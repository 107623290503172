// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("packs/jquery.doomEdit")
require("packs/draggs")
const { Dropzone } = require("dropzone");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//import 'bootstrap';
import './stylesheets/application.scss';
import 'materialize-css/dist/js/materialize'
import "chartkick/chart.js"
import "dropzone/dist/dropzone.css";

$(document).on('turbolinks:load', function () {

  if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    $('select').addClass('browser-default')
    $('label').css('position', 'relative');
  }
  Dropzone.autoDiscover = false; 

  if($('#upload-widget').length){
    let myDropzone = new Dropzone("#upload-widget",
    {
      paramName: 'file',
      maxFilesize: 250, // MB
      maxFiles: 200,
      dictDefaultMessage: 'Отпустите файл в этой области чтобы загрузить', 
      headers: {
        'x-csrf-token': document.querySelector('meta[name=csrf-token]').getAttributeNode('content').value,
      },
      acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf,.zip,.mov,.txt,.mp4,.z01,.z02,.z03,.z04,.z05,.z06,.z07,.z08,.z09,.z10,.p7s",

      init: function() {
        this.on('start', function(file, resp){ console.log('start!')})
        this.on('success', function(file, resp){
         // console.log(file);
         // console.log(resp);
        });
      },
    })  
  
  //console.log(myDropzone)
  }


  $(".image_preview").on("click", function(e) {
    //console.log('set click')
    //console.log('prevent event')
 });
 $("img.image_preview").on("click",function(evt){
   //console.log(this.parentElement.href)
        if(evt.shiftKey) {
          //console.log(this.id)
          var upload_src = this.id
          var upload_src_long = this.src.replace( 'original', 'thumb' )
          $.ajax({ 
              url: "/orders/<%= order.id%>", 
              method: "GET", 
              data: {"id_src": upload_src},
              success: function(data) {
                $('#order_thumb')[0].src = upload_src_long;
              } 
          });
        } else {
         $("#popup").html( $('<img>')
           .attr('src', this.parentElement.href)
           .attr('style', 'width:70%; display: block; margin-left: auto; margin-right: auto;') );
        }
    });

  updateTime();
  updateCommentsCount();
  window.addAjaxLoaderHandler();

  M.AutoInit();
  
  $('#order_insurance_objects_clients_name').on('blur', function() {
    var textValue = this.value;
    var posterID  = this.getAttribute('posterid');

    $.ajax({
      url: '/client_find',
      type: "GET",
      dataType: 'json',
      statusCode: {
        404: function () {
          console.log("page not found");
        },
        500: function () {
          console.log("неправильний параметр");
        }
      },
      message: 'Запитую дані',
      data: { client_find: textValue, poster_id: posterID},
      success: function (response) {
        //console.log(response)
        if (response != null && $('#order_insurance_objects_clients_telephone').val() == ''){
          var ins_object = JSON.parse(response["ins_message"])
          //console.log(ins_object)
          $('#titleIclient').show();
          $('#titleIclient')[0].title = 'Цей клієнт існує!'
          Swal.fire({
            title: 'Цей клієнт вже є в системі!',
            text: "Змінити ПІБ? (Створити нового), або залишити як є? (залишити без змін)",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Створити',
            cancelButtonText: 'залишити без змін'
          }).then((result) => {
            if (result.value) {
              Swal.fire(
                'Готово!',
                'До ПІБ клієнта додано 1, щоб його ПІБ був унікальний',
                'success'
              )
              $('#order_insurance_objects_clients_name').val(textValue+'(1)');
              $('#ins_list_div').hide();
              $('#titleIclient').hide();
            } else {
              $('#order_insurance_objects_clients_telephone').val(response["telephone"]);
              $('#order_insurance_objects_clients_telephone').attr('readonly', 'true')
              $('#ins_list_div').show();
              buildHtmlTable('#insObjectsList', ins_object);              
            }
          })
          /**if (confirm("Цей клієнт вже є в системі!\nЗмінити ПІБ? (Скасування), або залишити як є? (ОК)")) {
                //$('#titleIinsobj').hide();
            $('#order_insurance_objects_clients_telephone').val(response["telephone"]);
            $('#order_insurance_objects_clients_telephone').attr('readonly', 'true')
            $('#ins_list_div').show();
            buildHtmlTable('#insObjectsList', ins_object);
          } else {
            $('#order_insurance_objects_clients_name').val(textValue+'(1)');
            $('#ins_list_div').hide();
            $('#titleIclient').hide();
          }   **/       
        } else {
          $('#titleIclient').hide();
        }
      }
    });
  });

  //$('#order_insurance_objects_policy').on('keydown', function() {console.log('keydown');});
  $('#order_insurance_objects_policy').on('blur', function() {
    var textValue = this.value;
    //console.log(textValue);
    var posterID  = this.getAttribute('posterid');
    //console.log(posterID);
    $.ajax({
      url: '/ins_obj_find',
      type: "GET",
      dataType: 'json',
      statusCode: {
        404: function () {
          console.log("page not found");
        },
        500: function () {
          console.log("неправильний параметр");
        }
      },
      message: 'Запитую дані',
      data: { ins_obj: textValue, poster_id: posterID},
      success: function (response) {
        //console.log(response)
        if (response != null && $('#order_insurance_objects_brand').val() == ''){
          $('#titleIinsobj').show();
          $('#titleIinsobj')[0].title = 'Цей страховий договір існує! Змініть номер полісу, щоб змінити дані майна!'
          //alert('Данный страховой договор существует! Смените номер полиса, чтобы поменять данные имущества!')
         /* if (confirm("Цей страховий договір вже є в системі!\nЗмінити номер поліса? (скасувати), або залишити як є? (ОК)")) {*/
            //$('#titleIinsobj').hide();
  
           /* $('#order_insurance_objects_policy').val(response["policy"]);
            $('#order_insurance_objects_policy').attr('readonly', 'true')
          
            $('#order_insurance_objects_brand').val(response["brand"]);
            $('#order_insurance_objects_brand').attr('readonly', 'true')
          
            $('#order_insurance_objects_object_model_name').val(response["object_model_name"]);
            $('#order_insurance_objects_object_model_name').attr('readonly', 'true')
          
            $('#order_insurance_objects_nomer').val(response["nomer"]);
            $('#order_insurance_objects_nomer').attr('readonly', 'true')
          
            $('#order_insurance_objects_object_type').val(response["object_type"])
            $('#order_insurance_objects_object_type').attr('disabled', 'true')
            $('#order_insurance_objects_object_type').formSelect(); */
            
         /* } else {
            $('#order_insurance_objects_policy').val(textValue+'-1');
            $('#titleIinsobj').hide();
          } */
          $('#object_info').text(response["policy"]+'||'+response["brand"]+'||'+response["object_model_name"]+'||'+response["nomer"]+'||'+response["object_type"])
          $('#object_info_short').text('Номер договору: '+response["policy"]+', Обєкт: '+response["brand"]+' '+response["object_model_name"]+' '+response["nomer"])
          $('.modal').modal('open');
        } else {
          $('#titleIinsobj').hide();
        }
      }
    });
  });

  $("#user_filial").on('change', function() {
    $('#submit_user_show').click();
  });

  $("#user_company").on('change', function() {
    $('#submit_user_show').click();
  });

  $("#users_select_sch").on('change', function() {
    $('#submit_schedule').click();
  });

  $('.print-upload-button i').hover(
    function () {
      //console.log($(this).attr('class'));
      $(this).toggleClass("large");
      $(this).parent().toggleClass("print-upload-button_big");
    });

  $('.act-upload-button i').hover(
    function () {
      //console.log($(this).attr('class'));
      $(this).toggleClass("large");
      $(this).parent().toggleClass("act-upload-button_big");
    });

  $('#users th a, #users .pagination a').on('click', function () {
    $.getScript(this.href);
    return false;
  });

  $("#users_search").submit(function () {
    $.get(this.action, $(this).serialize(), null, "script");
    return false;
  });
  $("#users_search input").keyup(function () {
    $.get($("#users_search").attr("action"), $("#users_search").serialize(), null, "script");
    return false;
  });

  $('#orders th a, #orders .pagination a').on('click', function () {
    $.getScript(this.href);
    return false;
  });

  $("#orders_search").submit(function () {
    $.get(this.action, $(this).serialize(), null, "script");
    return false;
  });
  $("#orders_search input").keyup(function () {
    $.get($("#orders_search").attr("action"), $("#orders_search").serialize(), null, "script");
    return false;
  });

  $('#clients th a, #clients .pagination a').on('click', function () {
    $.getScript(this.href);
    return false;
  });

  $("#clients_search").submit(function () {
    $.get(this.action, $(this).serialize(), null, "script");
    return false;
  });

  $("#clients_search input").keyup(function () {
    $.get($("#clients_search").attr("action"), $("#clients_search").serialize(), null, "script");
    return false;
  });

  $('#insurance_objects th a, #insurance_objects .pagination a').on('click', function () {
    $.getScript(this.href);
    return false;
  });

  $("#insurance_objects_search").submit(function () {
    $.get(this.action, $(this).serialize(), null, "script");
    return false;
  });

  $("#insurance_objects_search input").keyup(function () {
    $.get($("#insurance_objects_search").attr("action"), $("#insurance_objects_search").serialize(), null, "script");
    return false;
  });

  $('#companies th a, #companies .pagination a').on('click', function () {
    $.getScript(this.href);
    return false;
  });

  $("#companies_search").submit(function () {
    $.get(this.action, $(this).serialize(), null, "script");
    return false;
  });

  $("#companies_search input").keyup(function () {
    $.get($("#companies_search").attr("action"), $("#companies_search").serialize(), null, "script");
    return false;
  });

  $('#bullet_cars th a, #bullet_cars .pagination a').on('click', function () {
    $.getScript(this.href);
    return false;
  });

  $("#bullet_cars_search").submit(function () {
    $.get(this.action, $(this).serialize(), null, "script");
    return false;
  });

  $("#bullet_cars_search input").keyup(function () {
    $.get($("#bullet_cars_search").attr("action"), $("#bullet_cars_search").serialize(), null, "script");
    //console.log('key up')
    if ($('#rst').is(":checked") || $('#rst').length <= 0 ) {
      //console.log($('#search').val().split(' ').length)
      if ($('#search').val().split(' ').length <= 2){
        //console.log('PYSTILO!!!');
        autoSearchGetRST();
      } else {
        $('#searchautocomplete-list').hide();
      }
    }
    //rstCalc();
    return false;
  });

  $("#order_insurance_objects_brand").keyup(function () {
    if ($('#order_insurance_objects_brand').val().split(' ').length <= 2){
      //console.log('PYSTILO!!!');
      autoSearchGetRSTforOrderForm();
    } else {
      $('#searchautocomplete-list').hide();
    }
  });

  $('.datepicker').datepicker({
    isRTL: false,
    format: 'dd.mm.yyyy',
    autoclose: true,
    i18n: {
      months: ["січень", "лютий", "березень", "квітень", "травень", "червень", "липень", "серпень", "вересень", "жовтень", "листопад", "грудень"],
      monthsShort: ["Сiч", "Лют", "Бер", "Квт", "Трв", "Червень", "Липень", "Серп", "Вер", "Жовтень", "Листопад", "Грд"],
      weekdays: ["неділя", "понеділок", "вівторок", "середа", "четвер", "п'ятниця", "субота"],
      weekdaysShort: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      weekdaysAbbrev: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      cancel: "Скасувати"
    }
  });

  $("input.global-autocomplete").keyup(function () {
    var q = this.value;
    //console.log(q);
    $.get('/site_search', $("input.global-autocomplete").serialize(), null, "script");
    /// return false;
  });
});

function remove_fields(link) {
  $(link).prev("input[type=hidden]").val("1");
  $(link).closest(".fieldset").hide();
}

function add_fields(link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g")
  $(link).parent().before(content.replace(regexp, new_id));
}

function stoppedTyping(e) {
  if (e.value.length > 0) {
    document.getElementById('comment_submit').disabled = false;
  } else {
    document.getElementById('comment_submit').disabled = true;
  }
}

function readURL(input, put_list) {
  //console.log(input.files.length);
  document.getElementById('comment_submit').disabled = false;
  clearImages();
  for (var i = 0; i < input.files.length; i++) {
    var file = input.files[i]
    var elem = document.createElement("img");
    elem.setAttribute("class", "image_preview")
    $(elem).attr('src', URL.createObjectURL(file))
    document.getElementById(put_list).appendChild(elem);
  }
}

function clearImages() {
  var myNode = document.getElementById("files_preview");
  while (myNode.lastElementChild) {
    myNode.removeChild(myNode.lastElementChild);
  }
}

function rgbToHex(col) {
  if (col.charAt(0) == 'r') {
    col = col.replace('rgb(', '').replace(')', '').split(',');
    var r = parseInt(col[0], 10).toString(16);
    var g = parseInt(col[1], 10).toString(16);
    var b = parseInt(col[2], 10).toString(16);
    r = r.length == 1 ? '0' + r : r; g = g.length == 1 ? '0' + g : g; b = b.length == 1 ? '0' + b : b;
    var colHex = '#' + r + g + b;
    return colHex;
  }
}

function resultof() {
  $('label').addClass('active');
  //  var br_ver = browserDetectNav();
  // Проверка ккомплектации тех состяние и длругих на заполнение
  $('#complektcia').text($('#estimates_equipment input:checked').length)
  $('#ex_defects').text($('#defects_div .fieldset').length)

  //установка артрибутов  
  $('#link_to_function').attr('onmousemove', 'resultof()');

  $('#estimate_car_new').attr('onchange', 'resultof()');
  $('#estimate_car_ua').attr('onchange', 'resultof()');
  $('#estimate_car_iznos').attr('onchange', 'resultof()');
  var sel = $('#estimate_c_type').val()

  $('#estimate_basic_form :input').attr('onchange', 'resultof()');
  $('#estimates_dz :input').attr('onchange', 'resultof()');
  $('#estimates_equipment :input').attr('onchange', 'resultof()');

  //$('input.defect-name').attr('onkeypress', 'getdefect()');

  // date_vipusk_text = $('.estimate-date-vipusk').text();
  // $('.estimate-date-vipusk').hide();
  // $('#estimate_date_vipusk').val(date_vipusk_text);
  //$('input.bootstrap-datepicker').removeClass( "bootstrap-datepicker").addClass('date').attr('type','text');
  //$('div.datepicker').hide()
  //срок експлуатации
  //document.getElementsByClassName('zvit-type-tag #estimate--ztype #estimate-zvit-type')[0].attr('onchange','resultof()');
  var zv_type = $('#estimate_zvit_type').val()
  var data_vipusk = $('#estimate_date_vipusk').val();

  var data_osmotra = $('#estimate_generate_date').val();//$('#data-osmotra').text().trim();

  if (data_vipusk == ''){
    data_vipusk = data_osmotra
  }

  var cur_date = new Date();
  //$('#estimate_generate_date').val(cur_date.toLocaleDateString("en-US"));
  //$('#label_generate_date').addClass('active');
  var cur_year = cur_date.getFullYear();

  var vipusk_date = new Date(data_vipusk.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'));
  var osmotr_date = new Date(data_osmotra.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'));
  var dt = Math.round(((osmotr_date.getTime() - vipusk_date.getTime()) / (1000 * 60 * 60 * 24) / 365) * 100) / 100;

  $('#srok-exp').text(dt);
  $('#srok-exp1').text(dt);
  //console.log(dt);
  var srok_exp = dt;

  // расчет Дз
  var dz_h = {
    "nonkoroz": 10, "kapremontkyz": 15, "zamenakyz": 30, "otsytavarpovregd": 5, "kapremontengine": 5, "carastaxi": -10, "thridetal": -10,
    "twodetal": -4, "viligecar": -10, "speccar": -10, "carmustdie": -10, "carcolordeath": -1, "pol": -4, "dah": -1, "longeron": -6,
    "longeron2": -6, "longeron3": -6, "longeron4": -6,
    "zadbokov": -0.3, "porog": -1, "porog2": -1,
    "krilozad": -1, "krilozad2": -1,
    "kriloper": -0.5, "kriloper2": -0.5,
    "peredok": -2, "peredok2": -2,
    "chitokper": -3, "kapot": -0.5, "panelperedka": -1,
    "bagag": -0.5, "brizg": -0.3, "defbezlfp": -5, "bokovinakyz": -4, "bokovinakyz2": -4,
    "perekos": -15, "arkibokovin": -2, "arkibokovin2": -2,
    "obivkasalon": -1,
    "panelibokov": -2, "panelibokov2": -2,
    "obivkaseat": -2, "doors": -0.5, "doors2": -0.5, "doors3": -0.5, "doors4": -0.5,
    "zadokkyzovy": -0.5, "policizadka": -0.5, "lfpkyzova": -3,
    "chromekyz": -3, "glassdead": -0.5
  }
  var dz_v = 0
  var m = $("input:checked").length;
  var mm = $("input:checked")
  for (var i = 0; i < m; i++) {
    for (var k in dz_h) {
      if (mm[i].id.split('_')[1] == k) { //console.log(k);
        if (((parseFloat(srok_exp) > 8) || (sel == 'cargo' || sel == 'bus')) && (k != 'nonkoroz') && (k != 'kapremontkyz') && (k != 'zamenakyz') && (k != 'otsytavarpovregd') && (k != 'kapremontengine') && (k != 'carastaxi') && (k != 'thridetal') && (k != 'twodetal') && (k != 'viligecar') && (k != 'speccar') && (k != 'carmustdie') && (k != 'carcolordeath')) { dz_v += dz_h[k] / 2; }//console.log(k+'=>'+dz_h[k]);//console.log('/2 esli nygno');}
        else { dz_v += dz_h[k]; }//  console.log(k+'=>'+dz_h[k]);//console.log('ne delim');}
        //console.log(dz_h[k]);
      }
    }
    //$$("input:checked")[0].checked = false;
  }
  $('#estimate_dz').val(dz_v);
  $('#tehstan').text(dz_v);
  // Пф-Пн
  var pf = $('#estimate_car_mileage').val();

  if (srok_exp < 1) { pf = Math.round((pf / 1000) * 100) / 100; }
  else { pf = Math.round(((pf / 1000) / srok_exp) * 100) / 100; }

  var pn = $('#estimate_pn').val();
  var pfpn = pf - pn;
  $('#pfpn').text(Math.round(pfpn * 100) / 100);
  $('#pfpn1').text(Math.round(pfpn * 100) / 100);
  // alert(pfpn);


  // Г 
  var gg = [100, 100, 100, 100, 99, 99, 98.5, 98, 97, 96, 96,
    100, 100, 100, 100, 99, 99, 98.5, 98.5, 98, 97, 97];
  var car_new = $('#estimate_car_new').is(":checked");
  var car_ua = $('#estimate_car_ua').is(":checked");
  var ii = 0;
  var num = 0;
  if (car_ua == true) { ii = 11 } else { ii = 0 };

  if (srok_exp < 1) {
    if (srok_exp < 0.12) {
      num = 1;
    } else {
      num = Math.round(((srok_exp / 0.08) + ii) - 1)
    };
    $('#estimate_g').val(gg[num]);
  } else {
    if (car_new == false) {
      $('#estimate_g').val(100)
    }
  }

  // alert(gg[6]);           
  var gp = parseFloat($('#estimate_g').val());
  var car_year = parseFloat($('#estimate_car_year').val());

  if ((car_year != '') || (car_year != '0')) {
    if (((cur_year - car_year) >= 1) && (srok_exp < 1)) {
      gp = gp - 2;
      if (gk_calc == false) {
        $('#estimate_g').val(Math.round(gp * 100) / 100)
      }
    }
  }

  //Гк
  //****************************************************************************
  //****************************************************************************
  var car_ua = $('#estimate_car_ua').is(":checked");
  var gk_calc = $('#estimate_gk_calc').is(":checked");
  var srok_ex = [[1, 6, 16, 150],
  [1, 2, 5, 9]];
  var srok_ex_ua = [1, 13, 150, 8, 150];
  var pn_a = [0, 15, 21, 500, 14, 18, 22, 500, 14, 500];
  var pn_a_ua = [[0, 11, 500],
  [1, 2, 4]];
  var pfpn_a = [1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 500];
  var pfpn_a_ua = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 500];
  var pereprobeg = [[2, 0.8, 0, 0, 1.2, 0, 0, 0, 1, 0],
  [2, 2, 1.6, 0.6, 3, 1.9, 1, 0.8, 2.9, 2],
  [2, 3.4, 2.6, 1.2, 4.6, 3.4, 2.2, 1.6, 4.6, 3.5],
  [2, 4.8, 3.7, 1.8, 6.1, 4.8, 3.3, 2.2, 6.1, 4.9],
  [2, 6.2, 4.8, 2.4, 7.6, 6.1, 4.3, 2.8, 7.6, 6.2],
  [2, 7.6, 5.9, 3.1, 9.1, 7.3, 5.2, 3.4, 8.0, 7.5],
  [2, 7.8, 7.0, 3.8, 9.3, 8.6, 6.1, 4.0, 8.2, 8.0],
  [2, 7.8, 7.2, 4.5, 9.5, 9, 7.0, 4.7, 8.4, 8.2],
  [3, 7.9, 7.4, 5.2, 9.7, 9.2, 7.9, 5.4, 8.6, 8.4],
  [3, 7.9, 7.6, 5.9, 9.9, 9.4, 8.5, 6.1, 8.8, 8.6],
  [3, 8.0, 7.7, 6.6, 10.0, 9.6, 8.8, 6.8, 8.9, 8.8],
  [3, 8.0, 7.8, 7.3, 10.0, 9.8, 9.0, 7.5, 9.0, 8.9],
  [3, 8.0, 7.9, 7.5, 10.0, 10.0, 9.2, 8.3, 9.0, 9.0],
  [3, 8.0, 8.0, 7.6, 10.0, 10.0, 9.4, 8.5, 9.0, 9.0],
  [3, 8.0, 8.0, 7.6, 10.0, 10.0, 9.6, 8.7, 9.0, 9.0],
  [3, 8.0, 8.0, 7.7, 10.0, 10.0, 9.8, 8.9, 9.0, 9.0],
  [3, 8.0, 8.0, 7.7, 10.0, 10.0, 9.9, 9.1, 9.0, 9.0],
  [3, 8.0, 8.0, 7.8, 10.0, 10.0, 10.0, 9.3, 9.0, 9.0],
  [4, 8.0, 8.0, 7.8, 10.0, 10.0, 10.0, 9.4, 9.0, 9.0],
  [4, 8.0, 8.0, 7.9, 10.0, 10.0, 10.0, 9.5, 9.0, 9.0],
  [4, 8.0, 8.0, 7.9, 10.0, 10.0, 10.0, 9.6, 9.0, 9.0],
  [4, 8.0, 8.0, 8, 10.0, 10.0, 10.0, 9.7, 9.0, 9.0],
  [4, 8.0, 8.0, 8, 10.0, 10.0, 10.0, 9.8, 9.0, 9.0],
  [4, 8.0, 8.0, 8, 10.0, 10.0, 10.0, 9.9, 9.0, 9.0],
  [4, 8.0, 8.0, 8, 10.0, 10.0, 10.0, 10.0, 9.0, 9.0]];
  var nedoprobeg = [[0, 0, 0, 0, 1.0, 0, 0, 0, 1.0, 0],
  [0, 0.8, 0, 0, 2.5, 1.7, 0.9, 0.7, 2.3, 1.1],
  [0, 0.8, 1.0, 0, 3.9, 3.1, 2.1, 1.4, 3.5, 1.9],
  [0, 0.8, 1.1, 0.3, 5.3, 4.2, 3, 2.1, 4.8, 2.7],
  [0, 2.1, 1.1, 0.3, 6.6, 5.3, 3.7, 2.6, 6, 3.6],
  [0, 2.1, 1.9, 0.3, 8, 6.4, 4.4, 3.1, 7.2, 4.4],
  [0, 3.3, 1.9, 0.8, 9.2, 7.5, 5.2, 3.6, 7.3, 5.2],
  [0, 4.4, 2.8, 0.8, 9.3, 8.7, 5.9, 4.0, 7.4, 6],
  [0, 5, 3.7, 1.3, 9.3, 8.8, 6.7, 4.5, 7.5, 6.8],
  [0, 5.5, 4.6, 1.9, 9.4, 8.9, 7.5, 5.0, 7.6, 7.5],
  [0, 6, 5.5, 2.8, 9.4, 9.0, 8.3, 5.4, 7.7, 7.6],
  [0, 6, 5.8, 3.1, 9.5, 9.1, 8.6, 5.9, 7.8, 7.6],
  [0, 6, 6, 3.6, 9.5, 9.1, 8.7, 6.4, 7.9, 7.7],
  [0, 6, 6, 4.2, 9.5, 9.1, 8.8, 6.8, 8.0, 7.7],
  [0, 6, 6, 4.8, 9.5, 9.1, 8.8, 7.3, 8.0, 7.8],
  [0, 6, 6, 5.5, 9.5, 9.1, 8.9, 7.7, 8.0, 7.8],
  [0, 6, 6, 6.1, 9.5, 9.1, 8.9, 8.2, 8.0, 7.8],
  [0, 6, 6, 6.7, 9.5, 9.1, 9.0, 8.4, 8.0, 7.9],
  [0, 6, 6, 7.3, 9.5, 9.1, 9.0, 8.5, 8.0, 7.9],
  [0, 6, 6, 7.9, 9.5, 9.1, 9.0, 8.6, 8.0, 7.9],
  [0, 6, 6, 8.2, 9.5, 9.1, 9.0, 8.7, 8.0, 8],
  [0, 6, 6, 8.2, 9.5, 9.1, 9.0, 8.8, 8.0, 8],
  [0, 6, 6, 8.2, 9.5, 9.1, 9.0, 8.9, 8.0, 8],
  [0, 6, 6, 8.2, 9.5, 9.1, 9.0, 8.9, 8.0, 8],
  [0, 6, 6, 8.2, 9.5, 9.1, 9.0, 9.0, 8.0, 8]];
  var sng_pereprobeg = [[3, 0, 0, 0, 0],
  [3, 0, 1.3, 0, 1.3],
  [3, 2.1, 1.3, 0, 2.7],
  [3, 2.1, 2.7, 1.8, 2.7],
  [3, 4.2, 4.2, 2.2, 4.1],
  [3, 6.3, 5.7, 2.7, 4.1],
  [3, 8.4, 7.3, 3.2, 5.4],
  [3, 9.4, 9, 3.7, 5.4],
  [3, 9.7, 9.1, 4.2, 6.8],
  [4, 9.9, 9.2, 4.7, 8.1],
  [4, 10.0, 9.3, 5.2, 9.4],
  [4, 10.0, 9.3, 5.7, 9.7],
  [4, 10.0, 9.3, 6.2, 9.7],
  [4, 10.0, 9.3, 6.7, 9.8],
  [4, 10.0, 9.3, 7.2, 9.8],
  [4, 10.0, 9.3, 7.6, 9.9],
  [4, 10.0, 9.3, 8.0, 9.9],
  [4, 10.0, 9.3, 8.4, 9.9],
  [5, 10.0, 9.3, 8.8, 9.9],
  [5, 10.0, 9.3, 9.0, 9.9],
  [5, 10.0, 9.3, 9.2, 9.9],
  [5, 10.0, 9.3, 9.3, 9.9],
  [5, 10.0, 9.3, 9.4, 9.9],
  [5, 10.0, 9.3, 9.5, 9.9],
  [5, 10.0, 9.3, 9.6, 9.9],
  [5, 10.0, 9.3, 9.7, 9.9],
  [5, 10.0, 9.3, 9.8, 9.9],
  [5, 10.0, 9.3, 9.9, 9.9],
  [5, 10.0, 9.3, 10.0, 9.9]];

  var sng_nedoprobeg = [[0, 0, 0, 0, 0],
  [0, 0, 1.2, 0, 2.6],
  [0, 0, 2.4, 0, 3.8],
  [0, 2.1, 3.4, 0, 5],
  [0, 4.1, 4.4, 0, 6],
  [0, 4.1, 5.3, 1.2, 7],
  [0, 5.8, 6.2, 2.0, 8],
  [0, 5.8, 7.6, 2.8, 8.8],
  [0, 6.2, 8.2, 3.5, 9.5],
  [0, 6.2, 8.8, 3.9, 9.6],
  [0, 6.2, 9.2, 4.2, 9.6],
  [0, 6.2, 9.2, 4.5, 9.6],
  [0, 6.2, 9.2, 4.9, 9.6],
  [0, 6.2, 9.2, 5.2, 9.6],
  [0, 6.2, 9.2, 5.5, 9.6],
  [0, 6.2, 9.2, 5.8, 9.6],
  [0, 6.2, 9.2, 6.1, 9.6],
  [0, 6.2, 9.2, 6.1, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6],
  [0, 6.2, 9.2, 6.2, 9.6]];

  var i, j, k, x, y, t1, t2, p = 0;
  var tmp, l, Gk = 0;
  if (car_ua == false) {
    //{Иномарки}
    for (var i = 1; i <= 3; i++) {
      if ((srok_exp >= srok_ex[0][i - 1]) && (srok_exp < srok_ex[0][i])) { p = i; break };
    }
    if (srok_ex[1][p] == srok_ex[1][3]) {
      t1 = 0;
      t2 = 1;
    } else {
      t1 = 1;
      t2 = -1;
    }

    for (var j = Math.round(srok_ex[1][p] - 1); j <= Math.round(srok_ex[1][p + t1] + t2); j++) {
      if (pn_a[j - 1] > pn_a[j]) { tmp = 0 } else { tmp = pn_a[j - 1] };
      if ((pn >= tmp) && (pn < pn_a[j])) { break };
    }
    if (Math.round(srok_ex[1][p]) == Math.round(srok_ex[1][p + 1] - 1)) { j = Math.round(srok_ex[1][p]) };
    if (srok_exp < 1) { j = 0 };
    x = j;
    for (var k = 1; k <= 25; k++) {
      if ((Math.abs(pfpn) >= pfpn_a[k - 1]) && (Math.abs(pfpn) < pfpn_a[k])) { break };
    }

    if (Math.abs(pfpn) < 1.5) { k = 0 };
    if (k == 26 || k == 25) { var Y = 24 } else { var Y = k; }


    if (pfpn > 0) { Gk = -pereprobeg[Y][x] } else { Gk = nedoprobeg[Y][x] };
  } else { //{СНГ}
    var pp = 0;
    for (var i = 1; i <= 3; i++) {
      if ((pn >= pn_a_ua[0][i - 1]) && (pn < pn_a_ua[0][i])) { pp = i; break };
    }
    if (pn == 0) { pp = 0 };
    if (pn_a_ua[1][pp] == pn_a_ua[1][2]) {
      t1 = 0;
      t2 = 1;
    } else {
      t1 = 1;
      t2 = -1;
    }

    for (var j = Math.round(pn_a_ua[1][pp] - 1); j <= Math.round(pn_a_ua[1][pp + t1] + t2); j++) {
      if (srok_ex_ua[j - 1] > srok_ex_ua[j]) { tmp = 0 } else { tmp = srok_ex_ua[j - 1] };
      if ((srok_exp >= tmp) && (srok_exp < srok_ex_ua[j])) { break };
    }
    //alert(Math.round(pn_a_ua[1][pp]-1));
    x = j;
    for (var k = 1; k <= 29; k++) {
      if ((Math.abs(pfpn) >= pfpn_a_ua[k - 1]) && (Math.abs(pfpn) < pfpn_a_ua[k])) { break };
    }
    if (Math.abs(pfpn) < 1) { k = 0 };
    //alert(k);
    if (k == 26 || k == 25) { var Y = 24 } else { var Y = k; }

    if (pfpn > 0) { Gk = -sng_pereprobeg[Y][x]; } else { Gk = sng_nedoprobeg[Y][x] };
  }


  //alert(x);
  if (gk_calc == false) { $('#estimate_gk').val(Math.round(Gk * 100) / 100) }
  //****************************************************************************
  //****************************************************************************


  // Остаточная ріночная стоимсоть     
  var cn = parseFloat($('#estimate_price').val());
  var cn_ost = parseFloat($('#estimate_price_ostat_remainse_ot').val());
  var kk = $('#estimate_k').val();
  var k = ''
  switch (kk) {
    case 'one':
      k = parseFloat('1');
      break;
    case 'zero_97':
      k = parseFloat('0.97');
      break;
    case "one_03":
      k = parseFloat('1.03');
      break;
  }

  //  alert(k);
  var kz = parseFloat($('#estimate_kz').val());
  var m = parseFloat($('#estimate_m').val());
  var gk = parseFloat($('#estimate_gk').val());
  var dz = parseFloat($('#estimate_dz').val());
  var sd = parseFloat($('#estimate_sdop').val());
  var sd_rem = sd;
  if (zv_type == 'remains' || zv_type == 'avarkom_remains' || zv_type == 'remainse_consult') {
    sd = 0
  } else {

  };
  //alert(zv_type);
  //if (zv_type == 'ostatki_zvit') { sd = 0}
  //                                 else 
  //                                {};
  var g = parseFloat($('#estimate_g').val());
  var c = 0;


  //    if (srok_exp < 1) {car_new = true;
  //                      $('#estimate_car_new').checked = true;};

  if (car_new == false) {
    //alert('false')
    c = (cn * k + m) * (1 + gk / 100 + dz / 100) + sd
    var c_ost = (cn_ost * k + m) * (1 + gk / 100 + dz / 100) + sd_rem
  }
  else {
    c = (cn * kz * (g / 100) * k + m) * (1 + gk / 100 + dz / 100) + sd
    var c_ost = (cn_ost * kz * (g / 100) * k + m) * (1 + gk / 100 + dz / 100) + sd_rem
    //alert('true')
  }
  //alert('Car_new='+car_new+'--- C='+c);

  $('#estimate_price_ostat').val(Math.round(c * 100) / 100)
  $('#estimate_price_ostat_remainse_do').val(Math.round(c_ost * 100) / 100)

  if (zv_type == 'ostatki_zvit' || zv_type == 'avarkom_remains') {
    $('#zal_sdod').innerHTML = Math.round((c + parseFloat($('#estimate_sdop').value)) * 100) / 100;
  } else {

  };


  //Ез
  var cnov = 0;
  var price = parseFloat($('#estimate_price').val());
  var price_new = parseFloat($('#estimate_price_new').val());
  var dz = parseFloat($('#estimate_dz').val());
  var car_iznos = $('#estimate_car_iznos').is(":checked");

  var ez = 0;
  if (car_new == true) { cnov = price } else { cnov = price_new };
  if (((srok_exp <= 7) && (car_ua == false)) || ((srok_exp <= 5) && (car_ua == true))) {
    if (pn != 0) {
      if ((pf / pn >= 2) || (dz < 0)) {
        if ((car_iznos == false) && ((cnov * kz) != 0)) { ez = 1 - (c / (cnov * kz)) }
        else { ez = 1 - (c / (cnov * kz)) };
      }
      if ((car_iznos == true) && ((cnov * kz) != 0)) { ez = 1 - (c / (cnov * kz)) };

    }
    else {
      if ((car_iznos == true) && ((cnov * kz) != 0)) { ez = 1 - (c / (cnov * kz)) }
      else { ez = 0 };
    }

  }
  else {
    if ((car_iznos == false) && ((cnov * kz) != 0)) { ez = 1 - (c / (cnov * kz)) }
    else { ez = 1 - (c / (cnov * kz)) };
  }

  if (srok_exp > 12) { ez = 0.7 };
  if (ez > 0.7) { ez = 0.7 };

  $('#estimate_ez').val(Math.round(ez * 10000) / 10000);

  //BTB

  var x = parseFloat($('#estimate_x').val());
  var cp = parseFloat($('#estimate_cpp').val());
  var cm = parseFloat($('#estimate_cm').val());
  var cz = parseFloat($('#estimate_cz').val());
  var a = 0
  var b = 0
  var btb = 0
  if (c != 0) { a = (cp + cm + cz) / c } else { a = 0 };
  if ((cz + cm) != 0) { b = cp / (cz + cm) } else { b = 0 };
  btb = x / 100 * (c + cp + cm + cz);

  $('#estimate_a').val(Math.round(a * 100) / 100);
  $('#estimate_b').val(Math.round(b * 100) / 100);
  $('#estimate_btb').val(Math.round(btb * 100) / 100);
  if ($('#estimate_ownerpay')[0].checked){}else{btb=0}

  //Материальний збиток
  var y = 0
  var ybez = 0
  var yy = 0
  var ezr = Math.round(ez * 10000) / 10000;
  y = (cp + cm + cz * (1 - ezr)) + btb;
  yy = (cp + cm + cz * (1 - ezr));
  ybez = (cp + cm + cz);
  //alert('Cp='+cp+'; Cm='+cm+'; Cz='+cz+'; Ez='+ezr);
  if (ybez >= c) { y = c }
  $('#estimate_cu').val(Math.round(y * 100) / 100);
  $('#ubez').text(Math.round(ybez * 100) / 100);
  $('#uznos').text(Math.round(yy * 100) / 100);

  // нормочасы
  
  $('#w_price').text( $('#estimate_w_price').val());
  $('#p_price').text( $('#estimate_p_price').val());
  // Фичи
  if (car_new == true) { $('#estimate_price_new').attr('disabled', 'true') } else { $('#estimate_price_new').removeAttr("disabled") };

  // Авто заполнение ущерба от повреждений
  var summ_cz = 0
  var summ_ci = 0
  var summ_cl = 0
  var summ_cn = 0

  var cz_inp = document.getElementsByClassName("defect-total-price");
  if (cz_inp.length != 0) {
    for (var i = 0; i < cz_inp.length; i++) {
      if (cz_inp[i].parentElement.parentElement.style.display != 'none') {
        summ_cz += parseFloat(cz_inp[i].value);
      }
    }
  } else { summ_cz = 0 }

  var ci_inp = document.getElementsByClassName("defect-work-price");
  if (ci_inp.length != 0) {
    for (var i = 0; i < ci_inp.length; i++) {
      if (ci_inp[i].parentElement.parentElement.style.display != 'none') {
        if (ci_inp[i].value != '') {
          summ_ci += parseFloat(ci_inp[i].value);
        }
      }
    }
  } else { summ_ci = 0 }

  var cl_inp = document.getElementsByClassName("defect-paint-price");
  if (cl_inp.length != 0) {
    for (var i = 0; i < cl_inp.length; i++) {
      if (cl_inp[i].parentElement.parentElement.style.display != 'none') {
        if (cl_inp[i].value != '') {
          summ_cl += parseFloat(cl_inp[i].value);
        }
      }
    }
  } else { summ_cl = 0 }

  var cn_inp = document.getElementsByClassName("defect-put-remove");
  if (cn_inp.length != 0) {
    for (var i = 0; i < cn_inp.length; i++) {
      if (cn_inp[i].parentElement.parentElement.style.display != 'none') {
        if (cn_inp[i].value != '') {
          summ_cn += parseFloat(cn_inp[i].value);
        }
      }
    }
  } else { summ_cn = 0 }

  var norm_work = parseFloat($('#estimate_w_price').val());
  var norm_paint = parseFloat($('#estimate_p_price').val());

  var color_type = $('#estimate_color_type').val();

  switch (color_type) {
    case 'akril_1':
      var har_paint = 0.6;
      break;
    case 'akril_2':
      var har_paint = 0.6;
      break;
    case 'metalic_2':
      var har_paint = 1;
      break;
    case 'perlamutr':
      var har_paint = 1.2;
      break;
  }

  var cz_avto = Math.round((summ_cz) * 100) / 100;
  var cmt_avto = Math.round(((summ_cz * 1.02) - summ_cz) * 100) / 100;
  var cm_avto = Math.round(((summ_cl * norm_paint) * har_paint) * 100) / 100 + (cmt_avto);
  var cp_avto = Math.round(((summ_cl * norm_paint) + ((summ_cn + summ_ci) * norm_work)) * 100) / 100;
  var ez_avto = parseFloat($('#estimate_ez').val());
  var u_avto = Math.round((cz_avto + cm_avto + cp_avto) * 100) / 100
  var uz_avto = Math.round((cz_avto * (1 - ez_avto) + cm_avto + cp_avto) * 100) / 100
  var uz_avto_bez_pdv = Math.round(((cz_avto * (1 - ez_avto))/1.2 + (cm_avto/1.2)+ cp_avto) * 100) / 100
  var cd_avto = $('#estimate_price_ostat').val();

  $('#Czview').text(Math.round((cz_avto) * 100) / 100);
  $('#Cmview').text(Math.round((cm_avto) * 100) / 100);
  $('#Cpview').text(Math.round((cp_avto) * 100) / 100);
  var uview = Math.round((cz_avto + cm_avto + cp_avto) * 100) / 100
  $('#Uview').text(uview)

  if (u_avto >= cd_avto) { $('#UZview').text(cd_avto) }
  else { 
    $('#UZview').text(uz_avto);
    $('#UZviewbezpdv').text(uz_avto_bez_pdv);
  }

  var av_year = $('#estimate_car_year').val();
  var av_volume = $('#estimate_car_engsize').val();
  var av_name = $('#brand').text().split('||')[0] + ' ' + $('#brand').text().split('||')[1];
  var av_num_bullet = $('#estimate_bullet_name').val();
  var av_est_date = $('#estimate_generate_date').val();

  if ($('#av_button').length > 0 ) {
    var av_button = $('#av_button')[0];
    var order_id = $('#order_id').text().split('|')[0].trim();
    if (av_year != '' && av_volume != '' && av_name != '') {
      av_button.href = '/estimate/get_bullet_cars/'+order_id+'?estimate_params=' + av_name + '||' + av_volume + '||' + av_year + '||' + av_num_bullet + '||' + av_est_date
      //console.log(' raz rz!');
      $('#av_button').removeClass('disabled')
    } else {
      $('#av_button').addClass('disabled')
    }
  }

     
  var car_brand_rst  = transliterate($('#brand').text().split('||')[0])
  var car_model_rst  = $('#brand').text().split('||')[1]
  var obem_rst = Math.round(($('#estimate_car_engsize').val() / 1000) * 10) / 10;
  
  var rst_button = $('#to_rst_search')[0];
  var rstb_button = $('#to_rstb_search')[0];

  if (av_year != '' && obem_rst != '') {
    rst_button.href = encodeURI('/bullet_cars?bulletens=&rst=on&search='+car_brand_rst+' '+car_model_rst+' '+av_year+' '+obem_rst)
    rstb_button.href = encodeURI('/bullet_cars?bulletens=&rst=on&remains=on&search='+car_brand_rst+' '+car_model_rst+' '+av_year+' '+obem_rst)
    //console.log(' raz rz!');
    $('#to_rst_search').removeClass('disabled')
    $('#to_rstb_search').removeClass('disabled')
  } else {
    $('#to_rst_search').addClass('disabled')
    $('#to_rstb_search').addClass('disabled')
  }

  //VIN Year 10 char
  var text = $('#estimate_car_vincode').val()
  var new_str_start ='<span style="color:red;font-weight: 900;">'
  var new_str_end ='</span>'
  var vinInfo = $('#vinInfo')
  if (text != ''){
    if (text.length == 17){
      vinInfo[0].innerHTML = text.substr(0,8)+new_str_start+'('+text[9]+')'+new_str_end+text.substr(10,text.length)
    }
  //  if (document.getElementById('frame_show').src.includes('&vin')){

  //  } else {
   //   $('#frame_show').attr('src' ,'https://forumauto.parts/laximo/vehicle_by_vin_frame?laximo_action=find_by_vin&vin='+text)
  //  }

  } else {
    vinInfo[0].innerHTML = ''
    //if (document.getElementById('frame_show').src == 'https://forumauto.parts/' ){

   // } else {
   //   $('#frame_show').attr('src' ,'https://forumauto.parts/')
   // }
  }

  //COLORED rem vozdeystvie

  var childDivs = $('.defect-rem-vozd');
  var inpPrices  = $('.defect-total-price');
  var inpRemonts = $('.defect-work-price');
  var inpPokrass = $('.defect-paint-price');
  var inpSnyats  = $('.defect-put-remove');
  var inpConPrices  = $('.defect-convert-price');

  for( i=0; i< childDivs.length; i++ )
  {
   var childDiv = childDivs[i];
   var inpPrice = inpPrices[i]; 
   var inpRemont = inpRemonts[i];
   var inpPokras = inpPokrass[i];
   var inpSnyat = inpSnyats[i];
   var inpConPrice = inpConPrices[i];

   var rem_vozd = childDiv.getElementsByTagName('select')[0].value
   if (rem_vozd == 'snyat'){
     childDiv.style.backgroundColor = '#eeeeee'
     inpPrice.disabled = true
     inpRemont.disabled = true
     inpPokras.disabled = true
     inpSnyat.disabled = false
   }
   if (rem_vozd == 'zamena'){
     childDiv.style.backgroundColor = '#ffab91'
     inpPrice.disabled = false
     inpRemont.disabled = true
     inpPokras.disabled = true
     inpSnyat.disabled = true    
   }   
   if (rem_vozd == 'pokraska'){
     childDiv.style.backgroundColor = '#ffe082'
     inpPrice.disabled = true
     inpRemont.disabled = true
     inpPokras.disabled = false
     inpSnyat.disabled = true
   }
   if (rem_vozd == 'remont' || rem_vozd == 'remont2' || rem_vozd == 'remont3' || rem_vozd == 'remont4' ){
     childDiv.style.backgroundColor = '#90caf9'
     inpPrice.disabled = true
     inpRemont.disabled = false
     inpPokras.disabled = true
     inpSnyat.disabled = true
   }
   if (rem_vozd == 'proverka'){
    childDiv.style.backgroundColor = '#b39ddb'
    inpPrice.disabled = true
    inpRemont.disabled = false
    inpPokras.disabled = true
    inpSnyat.disabled = true    
   }
   if (rem_vozd == 's_c' || rem_vozd == 's_p1' || rem_vozd == 's_p2' || rem_vozd == 's_o' ){
    childDiv.style.backgroundColor = '#009688'
    inpPrice.disabled = true
    inpRemont.disabled = true
    inpPokras.disabled = true
    inpSnyat.disabled = true
    inpConPrice.disabled = true
   }
  }

  //console.log($('#estimate_ez').val());
 // if ($('#estimate_ez').val() == 'NaN' || $('#srok-exp').text() == 'NaN' || $('#pfpn').text() == 'NaN') {
 //   $('#estimate_ez').val('0')
 //   $('#srok-exp').text('0')
  //  $('#pfpn').text('0')
 // }
}

function adresstotge() {
  $('#estimate_adr_time_osmtr').val('див. фото дод.');
  $('#estimate_adr_owner').val('див. фото дод.');
  $('#estimate_prisytni').val('див. фото дод.');

  $('#label_adr_time_osmtr').addClass('active');
  $('#label_adr_owner').addClass('active');
  $('#label_prisytni').addClass('active');
}

function setSrokExp() {
  //console.log('on blur!');
  var year = $('#estimate_car_year').val();
  var srok = $('#estimate_date_vipusk');
  var yeartosrok = srok.val().split(".")[2]
  //alert(year);
  if ((year != '') && (year.length > 3)) {
    if (year === yeartosrok) {
    } else {
      srok.val('01.01.'+year );
    }
  }
  $('#label_date_vipusk').addClass('active');
  resultof();
}

function vindecodeshow() {
  var vin = $('#estimate_car_vincode').val();

  var newWin1 = window.open("https://exist.ua/car-search/?catalog=&code=" + vin+'&task=vin');
  newWin1.focus();
}

function PromUAButtonShow(e, focus) {
      
      // Set url for PromUA
      if (focus == 'in'){
        $('#'+e.id).next().next().show('slow')
        var part = e.value
        var car_get = $('#brand').text().split('||')[0] +' '+ $('#brand').text().split('||')[1]+' '
        var url = "https://prom.ua/search?search_term="+car_get+part
        if (e.parentNode.nextElementSibling.querySelector('select').value == 'zamena' && part != ''){
          e.nextElementSibling.querySelector('a').href = url
          $('#'+e.id).next().show('slow')
          //e.nextElementSibling.querySelector('#promUAspan')
        }
      }
      if (focus == 'out') {
        $('#'+e.id).next().next().hide('slow')
        if (e.parentNode.nextElementSibling.querySelector('select').value == 'zamena'){
          $('#'+e.id).next().hide('slow')
        }
      }
}

function ChagedRemontVoz(e, focus){
  var id = e.parent().parent().find('input')[0].id.match(/\d+/)[0]
  //console.log(focus)
  //console.log(id)

  if (focus == 'E'){
    e.parent().parent().next().find('select').val('zamena')
    e.parent().parent().next().find('select').formSelect();
    setTimeout(function() { $('#estimate_defects_attributes_'+id+'_total_price').focus(); $('#estimate_defects_attributes_'+id+'_total_price').select();  }, 200);
  }
  if (focus == 'I'){
    e.parent().parent().next().find('select').val('remont')
    e.parent().parent().next().find('select').formSelect();
    setTimeout(function() { $('#estimate_defects_attributes_'+id+'_work_price').focus(); $('#estimate_defects_attributes_'+id+'_work_price').select();  }, 200);
  }
  if (focus == 'L'){
    e.parent().parent().next().find('select').val('pokraska')
    e.parent().parent().next().find('select').formSelect();
    setTimeout(function() { $('#estimate_defects_attributes_'+id+'_paint_price').focus(); $('#estimate_defects_attributes_'+id+'_paint_price').select();  }, 200);
  }
  if (focus == 'N'){
    e.parent().parent().next().find('select').val('snyat')
    e.parent().parent().next().find('select').formSelect();
    setTimeout(function() { $('#estimate_defects_attributes_'+id+'_put_remove').focus(); $('#estimate_defects_attributes_'+id+'_put_remove').select();  }, 200);
  }

}

function getdefect(e) {
  
  if (e.keyCode == 32){
    // Set url for PromUA
    var part = e.target.value
    var car_get = $('#brand').text().split('||')[0] +' '+ $('#brand').text().split('||')[1]+' '
    var url = "https://prom.ua/search?search_term="+car_get+part
    if (e.target.parentNode.nextElementSibling.querySelector('select').value == 'zamena'){
      e.target.nextElementSibling.querySelector('a').href = url
    }
//    console.log(e.target.nextElementSibling)
    //---
    var model = $('#brand').text().split('||')[1];
    var marka = $('#brand').text().split('||')[0];
    var god = $('#estimate_car_year').val();
    var ul = $('#def_list');
    var vin = $('#estimate_car_vincode').val()
    var table = $('#defectsList');
    if (table){
      table[0].innerHTML = "";
    }    
    if (ul) {
      while (ul.firstChild) {
        ul.removeChild(ul.firstChild);
      }
    }
    

  $('#nameone').show(500);
  // console.log(active_element); 
  //console.log(god); 
  var f_p = e.target.value;
  if (f_p.length >= 3) {
    $('#spinner').show();
    $.ajax({
      url: '/find_estimate_defects',
      type: "GET",
      dataType: 'json',
      statusCode: {
        404: function () {
          console.log("page not found");
        },
        500: function () {
          console.log("неправильний параметр");
        }
      },
      message: 'Запитую дані',
      data: { modelname: model+','+god+','+f_p+','+marka+','+vin },
      success: function (response) {      
       // console.log(marka+' '+model+' '+god+' '+f_p);
        //clear ul
        if (table){
          table.innerHTML = "";
        }    
        if (ul) {
            while (ul.firstChild) {
              ul.removeChild(ul.firstChild);
            }
          }
        //---
        var f_defect=response;
        //console.log(f_defect);
        for (var i in f_defect) {
          //console.log(i, f_defect[i].defect.name)
          var text =f_defect[i].name +'|'+ f_defect[i].rem_vozd+'|'+f_defect[i].total_price+'|'+f_defect[i].work_price+'|'+f_defect[i].paint_price+'|'+f_defect[i].put_remove
          addlitoul('def_list', text)
        }
          //console.log(text);    
          $('#spinner').hide();      
      },
    });
  }//end if on length f_p
  
  }// basic if end
}

function zamenaNaEILN(text){
  switch (text) {
    case "zamena":
      return 'E';
      break;
    case "remont":
      return 'I';
      break;
    case "pokraska":
      return 'L';
      break;
    case "snyat":
      return 'N';
      break;
    case "proverka":
      return 'P';
      break;
    default:
      return 'E';
  }
}

function addlitoul(element, textli) {
  var ul = document.getElementById(element);
  var li = document.createElement("li");
  var children = ul.children.length + 1
  //var est_id = textli.split("(")[1]

//  li.setAttribute("id", "element"+children)
//  li.setAttribute("onclick", "lireturn(this.innerHTML);")
  //li.setAttribute("style", "cursor: pointer;")

 // li.appendChild(document.createTextNode(textli));
//  ul.appendChild(li)

  var table = document.getElementById('defectsList');
  var tr = document.createElement('tr');   

  var td1 = document.createElement('td');
  var td2 = document.createElement('td');
    td2.setAttribute("id", "element"+children)
    td2.setAttribute("onclick", "lireturn('"+textli.split('=>')[1]+"');")
    td2.setAttribute("style", "cursor: pointer;")
  var td3 = document.createElement('td');
  var td4 = document.createElement('td');
  var td5 = document.createElement('td');
  var td6 = document.createElement('td');
  var td7 = document.createElement('td');

  var text1 = document.createTextNode(textli.split('=>')[0]);
  var text2 = document.createTextNode(textli.split('=>')[1].split('|')[0]);

  if ( textli.split('=>')[1].split('|')[1].includes("http") ){
    var text3 = document.createTextNode(zamenaNaEILN(textli.split('=>')[1].split('|')[3]));
    var text4 = document.createTextNode(textli.split('=>')[1].split('|')[4]);
    var text5 = document.createTextNode(textli.split('=>')[1].split('|')[5]);
    var text6 = document.createTextNode(textli.split('=>')[1].split('|')[6]);
    var text7 = document.createTextNode(textli.split('=>')[1].split('|')[7]);
  } else {
    var text3 = document.createTextNode(zamenaNaEILN(textli.split('=>')[1].split('|')[1]));
    var text4 = document.createTextNode(textli.split('=>')[1].split('|')[2]);
    var text5 = document.createTextNode(textli.split('=>')[1].split('|')[3]);
    var text6 = document.createTextNode(textli.split('=>')[1].split('|')[4]);
    var text7 = document.createTextNode(textli.split('=>')[1].split('|')[5]);
  }

  if (textli.split('=>')[1].split('|')[2] == '0.0'){
    text4 = document.createTextNode('');
  }

  if (textli.split('=>')[1].split('|')[3] == 'null'){
    text5 = document.createTextNode('');
  }
  if (textli.split('=>')[1].split('|')[4] == 'null'){
    text6 = document.createTextNode('');
  }
  if (textli.split('=>')[1].split('|')[5] == 'null'){
    text7 = document.createTextNode('');
  }
  td1.appendChild(text1);
  td2.appendChild(text2);
  td3.appendChild(text3);
  td4.appendChild(text4);
  td5.appendChild(text5);
  td6.appendChild(text6);
  td7.appendChild(text7);

  tr.appendChild(td1);
  tr.appendChild(td2);
  tr.appendChild(td3);
  tr.appendChild(td4);
  tr.appendChild(td5);
  tr.appendChild(td6);
  tr.appendChild(td7);

  table.appendChild(tr);
}

function lireturn(text){
  var name = text.split('|')[0].trim();
  var rem_v = text.split('|')[1];
  var price = text.split('|')[2];
  var i_v = text.split('|')[3];
  if(i_v == '' || i_v == 'null'){i_v = ''}
  var l_v = text.split('|')[4];
  if(l_v == '' || l_v == 'null'){l_v = ''}
  var n_v = text.split('|')[5];
  if(n_v == '' || n_v == 'null'){n_v = ''}


  $('.defect-name').last().val(name)
  $('.defect-rem-vozd').last().children().children().last().val(rem_v)
  $('.defect-rem-vozd').last().children().children().last().formSelect();
  $('.defect-total-price').last().val(price)
  $('.defect-work-price').last().val(i_v)
  $('.defect-paint-price').last().val(l_v)
  $('.defect-put-remove').last().val(n_v)

  $('#nameone').toggle();
  resultof();
}

function setBulletHour(el) {
 // console.log(el.value);
  if (el.value == '') {
   var marka = $('#brand').text().split('||')[0];
   var ord_id = $('#order_id').text().trim();
   var year =  $('#estimate_car_year').val();
   var est_param = 'bulet/'+marka+'/'+ord_id+'/'+year
   //console.log(est_param)
      $.ajax({
      url: '/get_cars',
      type: "GET",
      dataType: 'json',
      statusCode: {
        404: function () {
          console.log("page not found");
        },
        500: function () {
          console.log("неправильний параметр");
        }
      },
      message: 'Запитую дані',
      data: { carsfind: est_param},      
      success: function(response) {
               var f_est=response;
              // console.log(f_est)
              // console.log(f_est[0])
               var perv = f_est[0]
               if (f_est[0] == undefined) {
                 $('#estimate_bullet_name').val(f_est.bullet_name);
            		 alert('Нормо часы не нашли, ставим в ручную!');
               } else {
                 $('#estimate_bullet_name').val(f_est[0].bullet_name);
                 $('#estimate_w_price').val(f_est[0].w_price);
                 $('#estimate_p_price').val(f_est[0].p_price);
               }
               resultof();
        }
      });     
  }
}

function krott() {
  var brand = $('#brand').text().split('||')[0] + ' ' + $('#brand').text().split('||')[1]
  var god = $('#estimate_car_year').val();
  var obem = parseFloat($('#estimate_car_engsize').val());
  var bull = $('#estimate_bullet_name').val().match(/\d+/)[0];
  var d_kyrs = $('#estimate_generate_date').val()
  if (d_kyrs == '') {
    var new_date = new Date().toLocaleDateString("en-US");
    var date_kyrs = new_date.split('/')[1] + '.' + new_date.split('/')[0] + '.' + new_date.split('/')[2];
  } else {
    var date_kyrs = d_kyrs;
  }

  //alert(bull);
  var ob = Math.round((obem / 1000) * 10) / 10;
  if (ob == 1) { ob = '1.0' };
  if (ob == 2) { ob = '2.0' };
  if (ob == 3) { ob = '3.0' };
  if (ob == 4) { ob = '4.0' };
  if (ob == 5) { ob = '5.0' };
  if (ob == 6) { ob = '6.0' };

  var newWin = window.open("http://krott.net.ua/bullet_cars?num_b=﻿" + bull + "&search=" + brand + " " + god + " " + ob + "&date_estimate=" + date_kyrs, "Krott", "alwaysRaised=yes, width=1100, height=530, resizable=no, scrollbars=yes, status=yes");
  newWin.focus();
}

function rst(){
  var newWin = window.open("https://rst.ua/oldcars/?task=newresults&make%5B%5D=0&year%5B%5D=0&year%5B%5D=0&engine%5B%5D=0&engine%5B%5D=0&gear=0&fuel=0&drive=0&s=4&price%5B%5D=0&price%5B%5D=0");
  newWin.focus();
}

function hideActview() {
  var x = document.getElementById("act_link");
  if (x.style.display === "none") {
    $('#act_link').show();
    $("#icon_act_view")[0].innerText = 'arrow_drop_up'
  } else {
    $('#act_link').hide();
    $("#icon_act_view")[0].innerText = 'arrow_drop_down'
  }
}

function hideImageview() {
  var x = document.getElementById("image_collection_left");
  if (x.style.display === "none") {
    $('#image_collection_left').show('slow');
    $("#image_hide_icon")[0].innerText = 'backspace'
  } else {
    $('#image_collection_left').hide('slow');
    $("#image_hide_icon")[0].innerText = 'add_circle'
  }
}

function hiderightInfo() {
  var x = document.getElementById("rightInfo");
  if (x.style.display === "none") {
    $('#rightInfo').show('slow');
    $("#rightInfo_hide_icon")[0].innerText = 'close'
  } else {
    $('#rightInfo').hide('slow');
    $("#rightInfo_hide_icon")[0].innerText = 'add_box'
  }
}

function hideCatalog() {
  var x = document.getElementById("frame_catalog");
  if (x.style.display === "none") {
    $('#frame_catalog').show('slow');
    $("#image_hide_icon_catalog")[0].innerText = 'clear'
  } else {
    $('#frame_catalog').hide('slow');
    $("#image_hide_icon_catalog")[0].innerText = 'build'
  }
}

function setPrice(tr) {
  var pn = tr.getElementsByTagName("td")[2].innerHTML
  var price = tr.getElementsByTagName("td")[5].innerHTML
  var price_prived = tr.getElementsByTagName("td")[6].innerHTML.split(' ')[0]
  $('#estimate_price').val(price.trim());
  $('#label_price').addClass('active');
  $('#estimate_pn').val(pn);
  $('#label_pn').addClass('active');
  $('#estimate_price_new').val(price_prived);
  $('#label_price_new').addClass('active');

  $('#cars_list').toggle();
}

function newwindow() {
  $('#au1').focus();
  //console.log($('#modal_audatex').is(":visible") == false);
  if ($('#modal_audatex').is(":visible") == false) {
    $('#modal_audatex').show('slow');
  } else {
    $('#estimate_cpp').val((parseFloat($('#au1').val()) + parseFloat($('#au2').val()) + parseFloat($('#au3').val())) * (parseFloat($('#nds').val())));
    $('#estimate_cm').val((parseFloat($('#au4').val()) + parseFloat($('#au6').val())) * (parseFloat($('#nds').val())));
    $('#estimate_cz').val((parseFloat($('#au5').val())) * (parseFloat($('#nds').val())));
    $('#modal_audatex').hide('slow');
  }
}


function getResult(){
  var calc = $('#estimate_raw_calc').val();
  var x = calc;
  var r = /\\u([\d\w]{4})/gi;
  x = x.replace(r, function (match, grp) {
    return String.fromCharCode(parseInt(grp, 16));
  });

  x = unescape(x);

  var a = [];
  //when PDF
  a = x.split("\n")
  var rabot = ''
  var rabot_p = ''
  var rabot_dod = ''
  var mater = ''
  var zapch = ''
  var metiz = ''

  var rabot_c_a = 0
  var rabot_c_p = 0
  var rabot_c_dod = 0
  var mater_c = 0
  var zapch_c = 0
  var metiz_c = 0

  var vin_c =  ''
  var year_c = ''


  console.log('PDF is -> '+String(a.length >= 1))
  
  if (a.length <= 1){
    //when response
    a = x.split("\\r\\n")
  }


  for (var i = 0; i < a.length - 1; i++) {  
    
    if(a[i].includes('ЗАГАЛЬНА СУМА ВАРТОСТІ РОБІТ')){
      rabot = a[i]
      console.log('rabot')
      console.log(rabot)
    }
    if(a[i].includes('ВАРТІСТЬ РОБІТ')){
      rabot_p = a[i]
      console.log('rabot_p')
      console.log(rabot_p)
    }
    if(a[i].includes('ЗАГАЛЬНА СУМА ДОД ВИТРАТ')){
      rabot_dod = a[i]
      console.log('rabot_dod')
      console.log(rabot_dod)
    }
    if(a[i].includes('ВАРТІСТЬ МАТЕРІАЛІВ')){
      mater = a[i]
      console.log('mater')
      console.log(mater)
    }
    if(a[i].includes('З А П А С Н І Ч А С Т И Н И')){
      zapch = a[i]
      console.log('zapch')
      console.log(zapch)
    }
    if(a[i].includes('МАЛОЦІННІ ДЕТАЛІ')){
      metiz = a[i]
      console.log('metiz')
      console.log(metiz)
    }
    if(a[i].includes('№ ШАСІ ')){
      vin_c = a[i]
      console.log('vin_c')
      console.log(vin_c)
      vin_c = vin_c.split(' ')[2]
    } 
    if(a[i].includes('ПЕРША РЕЄСТР')){
      year_c = a[i]
      console.log('year_c')
      console.log(year_c)
      year_c= year_c.split('Р')[3].split('.')[2]
    } 
  };

  rabot !== '' ? rabot_c_a = parseFloat(rabot.replace(/\D/g, ""))/100 : rabot_c_a = 0;
  rabot_p != '' ? rabot_c_p = parseFloat(rabot_p.replace(/\D/g, ""))/100 : rabot_c_p = 0; 
  rabot_dod != '' ? rabot_c_dod = parseFloat(rabot_dod.replace(/\D/g, ""))/100 : rabot_c_dod = 0; 
  mater != '' ? mater_c = parseFloat(mater.replace(/\D/g, ""))/100 : mater_c = 0; 
  zapch != '' ? zapch_c = parseFloat(zapch.replace(/\D/g, ""))/100 : zapch_c = 0; 
  metiz = metiz.replace(/\D/g, "").substring(2, metiz.length)
  metiz != '' ? metiz_c = parseFloat(metiz)/100 : metiz_c = 0; 

 $('#estimate_cpp').val(rabot_c_a+rabot_c_p+rabot_c_dod)
 $('#estimate_cm').val(mater_c+metiz_c)
 $('#estimate_cz').val(zapch_c)

 var vin = $('#estimate_car_year').val()
 var year = $('#estimate_car_year').val()

 if ( vin === '' && year === ''){
  vin_c != '' ? $('#estimate_car_vincode').val(vin_c) : ''
  year_c != '' ? $('#estimate_car_year').val(year_c) : ''
 }

 resultof();
}

function nextelement(nomer, men) {
  $(men).val($(men).val().replace(',', '.'));
}

function hideRawCalc() {
  var x = document.getElementById("raw_calc_view");
  if (x.style.display === "none") {
    $('#raw_calc_view').show();
    $("#raw_calc_hide_icon")[0].innerText = 'close'
  } else {
    $('#raw_calc_view').hide();
    $("#raw_calc_hide_icon")[0].innerText = 'add_box'
  }
}


function hideRawCalcReal() {
  var x = document.getElementById("raw_calc_view_real");
  if (x.style.display === "none") {
    $('#raw_calc_view_real').show();
    $("#raw_calc_hide_icon_real")[0].innerText = 'arrow_drop_up'
  } else {
    $('#raw_calc_view_real').hide();
    $("#raw_calc_hide_icon_real")[0].innerText = 'arrow_drop_down'
  }
}

function add_raw_calc() {
  var calc = document.getElementById("estimate_raw_calc_in_view").innerHTML;
  var calc_arr = calc.replace('{"cp":"   ', '').replace('"}','').split(/\\r\\n/);
  //console.log(calc_new);
  var x = document.getElementById("div_raw_calc_view_for_print");
  var calc_new =''
  for (var i = 1; i < calc_arr.length - 1; i++){
    var x1 = calc_arr[i];
    var r = /\\u([\d\w]{4})/gi;
    x1 = x1.replace(r, function (match, grp) {
      return String.fromCharCode(parseInt(grp, 16));
    });
  
    x1 = unescape(x1);
    //if (x1.includes('href')){
    //  console.log(x1)
    //  console.log(x1.replace(/&lt;/g,"<").replace(/&gt;/g, ">").replace(/<[^>]*>/g, "").replace("&lt;","<").replace("&gt;", ">").replace('</a>',''))
   // }

    x1 = x1.replace(/&lt;/g,"<").replace(/&gt;/g, ">").replace(/<[^>]*>/g, "").replace("&lt;","<").replace("&gt;", ">").replace('</a>','')
    x1 = x1.replace(/&amp;gt;/g, "")

    calc_new += x1+"<br/>" 
  }

  if (calc.length >1) {
    if (x.style.display === "none") {
      document.getElementById('raw_calc_view_for_print').innerHTML ='<div id="pre_div"><pre style="padding: 0; border: 0; font: normal 1.4rem Courier New,Courier,monospace; margin: 5rem auto;">' + 
                                                                     calc_new+'</pre></div><table style="page-break-after: always;" ></table>';
    } else {
      document.getElementById('raw_calc_view_for_print').innerHTML ='';
    }
  } else {
    alert('Сырая калькуляция отсутствует!');
    $('#raw_view_label').prop('checked', false); 
  }
  
} 

function get_defects_res(calc) { //сырая калькуляция
  //console.log(calc);
  var x = calc;
  var r = /\\u([\d\w]{4})/gi;
  x = x.replace(r, function (match, grp) {
    return String.fromCharCode(parseInt(grp, 16));
  });

  x = unescape(x);

  var a = [];
  //var b = [];
  var j = 0;
  var work = 0;
  var paint = 0;
  var parts = 0;
  var start = 0;
  var ael = ''
  var aw = parseFloat($('#estimate_aw_audatex').val());

  for (var i = 0; i < x.length - 1; i++) { if ((x[i] == 'n') && (x[i + 1] == ' ')) { a.push(x.substring(j, i - 3).replace(/<a\b[^>]*>(.*?)<\/a>/i, "")); j = i + 5; }; };

  if (a.length == 0){
    for (var i = 0; i < x.length - 1; i++) { if ((x[i]  === '\n')) { a.push(x.substring(j, i-1).replace(/<a\b[^>]*>(.*?)<\/a>/i, "")); j = i + 1; }; };
  }

  for (var i = 0; i < a.length; i++) { if (a[i].includes('РОБ') && (a[i].includes('ПОЗ'))) { start = i; break; } };
  for (var i = 0; i < a.length; i++) { if (a[i].includes('З В Е Д Е Н І  Д А Н І  П О  Р О З Р А Х У Н К У') || a[i].includes('З В Е Д Е Н І Д А Н І П О Р О З Р А Х У Н К У ')) { var count = i + 1; break; } };
  //console.log(count);
  //var count = a.length - 100; //для маленьких калькулций не проверяли

  //if ($('.defect-name').length == 0 || $('.defect-name')[0].parentElement.parentElement.style.display === 'none') {
    $('.add-button a').click();
    if ($('#estimate_defects_attributes_0__destroy') == null) {
      alert('Щоб додати пошкодження, додайте одне порожнє пошкодження!');
    } else {

      $('#body-overlay').show();
      $('#prgressdef').show();
      $('#set_defects').hide();
      var i = start;
      var ic = 0;

      function myLoop() {
        setTimeout(function () {
          if ($('#estimate_defects_attributes_0__destroy') != null) {
            $('.optional').addClass('active');
            $('#prgressdef').text(((i * 100 / (count)).toFixed(2)).toString() + '%');

            ael = a[i].replace(/ +/g, " ").split(' ');
            //работы
            if (ael[ael.length - 1].includes('.') && work == 0) {
              var wname = ''
              for (var l = 1; l < ael.length; l++) {
                if ((/[а-яієїґ\']+/ig.test(ael[l]))) {
                  wname += ' ' + ael[l]
                }
              }
              $('.defect-name').last().val(wname.trim());
              //console.log(wname.trim())
             // console.log(ael)
             // console.log(ael[ael.length - 3])
              if (ael[ael.length - 2].includes('*') || ael[ael.length - 3].includes('РЕМ')) {
                //document.getElementsByName("estimate[defects_attributes]["+ic+"][rem_vozd]")[0].value = 'I';
                $('.defect-rem-vozd').last().children().children().last().val('remont');
                $('.defect-rem-vozd').last().children().children().last().formSelect();
                $('.defect-work-price').last().val(parseFloat(ael[ael.length - 2].replace('*', '')) / aw);
              //  console.log('remont!')
              } else {
                //document.getElementsByName("estimate[defects]["+ic+"][rem_vozd]")[0].value = 'N';
                $('.defect-rem-vozd').last().children().children().last().val('snyat');
                $('.defect-rem-vozd').last().children().children().last().formSelect();
                $('.defect-put-remove').last().val(parseFloat(ael[ael.length - 2].replace('*', '')) / aw);
              }
              $('.defect-total-price').last().val('0.0');

              if (i <= count - 2) {
                $('.add-button a').click();
                resultof();
              }

              if (a[i + 1].includes('-----------')) { work = 1; };
              ic++;
            }
            //**********
            //находим конец работ
            if (ael.length > 5 && ((ael[0].includes('Ф') && ael[1].includes('А') && ael[2].includes('Р') && ael[3].includes('Б')) || (ael[0].includes('ФАРБУВАННЯ') || (ael[0].includes('Ф А Р Б У В А Н Н Я'))))) {
              work = 1;
              //console.log('ЗНАЙШЛИ КІНЕЦЬ РОБОТ !!!!!!!!!!');
            }; //**********
            //console.log('work='+work+'-'+i);
            //console.log('paint='+paint+'-'+i);
            //console.log('parts='+parts+'-'+i);
            //console.log('************************');
            // покраска                        
            if (((ael[ael.length - 1].replace(/(?:\\[rn])+/g, "").match(/^[0-9.]+$/) != null) && (a[i].includes('ФАРБ')) &&
              (!ael[1].includes('СИСТЕМА')) && (!ael[1].includes('ЧАС') && (!ael[2].includes('ФАРБУВАННЯ'))) && (paint == 0) && (work == 1))
            ) {
              work = 1;
              var pname = ''
              for (var l = 0; l < ael.length; l++) {
                if ((/^[\u0400-\u04FF ]+$/.test(ael[l]))) {
                  pname += ' ' + ael[l];
                }
              }
              $('.defect-name').last().val(pname.trim());
              //document.getElementsByName("estimate[defects]["+ic+"][rem_vozd]")[0].value = 'L';
              $('.defect-rem-vozd').last().children().children().last().val('pokraska');
              $('.defect-rem-vozd').last().children().children().last().formSelect();
              $('.defect-total-price').last().val('0.0');
              $('.defect-paint-price').last().val(Math.round(parseFloat(ael[ael.length - 1].replace('*', '')) / aw * 3 * 100) / 100);
              if (i <= count - 2) {
                $('.add-button a').click(); resultof();
              }
              ic++;
            }

            //**********
            //наъодим конец покраски
            //console.log(ael[ael.length-5]);
            if (ael.length > 5 && ((ael[ael.length - 5].includes('ЦІНИ') && ael[ael.length - 4].includes('СТАНОМ'))) || (ael[0].includes('ЛАКОФАРБОВИЙ'))) {
              work = 1; paint = 1;
             // console.log('нашли конец покраски');
            }; //**********
            //находим конец запчастей
            //console.log(ael);
            //console.log(ael[ael.length - 2]+'=='+ael[ael.length-1]);
            //console.log(i);
            //console.log(work);console.log(paint);console.log(parts);
            if (((ael[ael.length - 1].includes('UAH'))||(ael[ael.length - 1].includes('UA'))) && (ael[ael.length - 2].includes('UAH'))) {
              //parts = 1; 
            //  console.log('КОНЕЦ ЗПЧ!!!!!!!!!!!!!!!');
              //$('#estimate_defects_attributes_'+ic+'_remove').click();
              $('.remove-button a').last().click();
              $('#body-overlay').hide();
              $('#prgressdef').hide();
              alert('Формування калькуляції закінчено!');
            }//**********
            //запчасти

            //console.log(ic);
           // console.log('a[i] - >'+a[i])
           // console.log('ael[ael.length - 1] ->'+ael[ael.length - 1])
           // console.log('ael[ael.length - 2] - >'+ael[ael.length - 2])
           // console.log('ael[0] - >'+ael[0])
           // console.log('ael[1] - >'+ael[1])
            //console.log('ael[ael.length - 4] - >'+ael[ael.length - 4])
//&& !ael[ael.length - 2].includes(':')
            if ((ael[ael.length - 1].includes('.') && !ael[ael.length - 4].includes('СТАНОМ') && !a[i].includes('ФАРБ') && !a[i].includes('НОРМОГОДИНИ') &&
              !a[i].includes('МАТЕРІАЛ') && !a[i].includes('М О Н Т Н А К А Л Ь К У Л Я Ц І Я')) && (work == 1) && (paint == 1) && (parts == 0)) {
              paint = 1;
              var sname = ''
              var scode = ''
              for (var l = 1; l < ael.length - 1; l++) {
                if ((/[а-яієїґ\']+/ig.test(ael[l]))) {
                  sname += ' ' + ael[l]
                }
                else {
                  if (l != ael.length) {
                    scode += ael[l];
                  }
                }
              }

              $('.defect-name').last().val(sname.trim() + '(' + scode.trim() + ')');
              //document.getElementsByName("estimate[defects]["+ic+"][rem_vozd]")[0].value = 'E';
              $('.defect-rem-vozd').last().children().children().last().val('zamena');
              $('.defect-rem-vozd').last().children().children().last().formSelect();
              $('.defect-total-price').last().val(parseFloat(ael[ael.length - 1].replace('*', '')));

              if (i <= count - 2) {
                $('.add-button a').click();
                resultof();
              }

              ic++;
            }
            //**********

          }

          i++;
          if (i < count) {
            myLoop();
          }
        }, 5)
      }

      myLoop();
    }
 // } else { alert(' Сначала удалите существующие дефекты!'); }
}

function remove_all_defects() {
  var r = confirm("Видалити всі пошкодження?!");
  var x = $('.def_button').length - 1;
 // console.log('кількість знайдених дефектів =' + x);
  if (r == true) {
    var i = 0;
    function myLoop() {
      setTimeout(function () {
      //  console.log('delete defect!')
        $('.def_button')[i].click();
        resultof();
        i++;
        if (i < x + 1) {
          myLoop();
        }
      }, 5)
    }
    myLoop();
  } else { }
}

function fpart(typeSite, MaxMin) {
  $('#spinner').show('slow');
  var r = confirm("Знайти ціни в інтернеті?");
  if (r) {
    var arr = []
    var num_el = []
    var res_find = []
    var k = 0
    var p_price = ''
    var p_color = ''
    var p_url = ''
    var brand = $('#brand').text().split('||')[0]
    if (brand == 'toyota' || brand == 'Toyota' || brand == 'TOYOTA') {
      brand = 'toyotalexus'
    }
   // console.log(brand);
    var rem_e = $('.defect-rem-vozd select')
    for (var i = 0; i < rem_e.length; i++) {
      if (rem_e[i].value == 'zamena') {
        var f = $('.defect-name')[i].value;
        if (f.includes('https')) {

        } else {
          var in1 = f.indexOf("(");
          var in2 = f.indexOf(")");
          var name_raw = f.substr(0, in1);
          var code = f.substr(in1, in2).replace('(', '').replace(')', '').replace(/ /g,'');
          if (code == 'KN') {

          } else {
            arr.push(code);
            if (name_raw != ''){
              $('.defect-name')[i].value = name_raw+'('+code+')'
            }
            //console.log(code)
            num_el.push(i);
          }
        }
      }
    };
    var max = arr.length;
    //console.log('max -> ',max)
    //$('loadimage').show(); 
    if (max == 0) {
      alert('Запчастини для пошуку відсутні або знайдені!');
      $('#spinner').hide();
    }
    var find = 0;
    for (var i = 0; i < max; i++) {
      //console.log(num_el);
      //console.log(arr[i]);
      //var gg = num_el[i];
      $.ajax({
        url: '/findparts',
        type: "GET",
        dataType: 'json',
        statusCode: {
          404: function () {
            console.log("page not found");
            find += 1
            if (find >= max-1){
              $('#spinner').hide('slow');
              $('#end_find_part').show('slow');
              //alert('Поиск закончен!');
             // console.log('Поиск закончен!');
            }
          },
          500: function () {
            console.log("неправильний параметр");
            find += 1
            if (find >= max-1){
              $('#spinner').hide('slow');
              $('#end_find_part').show('slow');
              //alert('Поиск закончен!');
             // console.log('Поиск закончен!');
            }
          }
        },
        message: 'Запитую дані',
        data: { paramfind: arr[i] + '/' + brand + '/' + typeSite + '/' + MaxMin },
        success: function (response) {

          //alert(response)
          console.log(response);  
          res_find.push(response.responseJSON);

          var url = response.split('*')[0]
          var price = response.split('*')[1]
          var product_id = response.split('*')[2]
          var page_id = response.split('*')[3]

          //console.log(url + '***' + price);

          //console.log(url+'*****'+price);
          if (typeSite == 'eparts') {
            code = url.split('art')[2].split('&')[0].split('=')[1];
          }

          if (typeSite == 'exist') {
            code = url.split('=')[1].split('&')[0];
          }

          if (typeSite == 'avtopro') {
            code = url.split('-')[3].split('/')[0];
          }

          if (typeSite == 'dokua') {
            code = url.split('=')[1].split('|')[0];
            //console.log(code)
          }

          var id = getInputsByValue(code)[0].id.split('_')[3]
          var first_price = $('#estimate_defects_attributes_' + id + '_total_price').val();

          if (price == 0) {
            p_price = $('#estimate_defects_attributes_' + id + '_total_price').val()
            p_color = 'red;'
            p_url = ''
          } else {
            //console.log('mi tyt');
            //console.log(price+'>'+parseFloat(first_price) +'='+ (price > parseFloat(first_price)));
            if (price > parseFloat(first_price) && first_price != 0) {
              p_price = first_price
              p_color = 'chartreuse;'
              if (typeSite == 'exist'){ // only EXIST
                p_url = '|https://exist.ua/search/?product_id='+product_id+'|'
              }
              if (typeSite == 'dokua'){ // only DOK.UA
                p_url = '|https://dok.ua/result2/art?id='+page_id+'|'
              }
             if (typeSite != 'dokua' && typeSite != 'exist') {
                p_url = url
              }

              // console.log('srabaotalo');
            } else {
              p_price = price
              p_color = 'blue;'
              if (typeSite == 'exist'){ // only EXIST
                p_url = '|https://exist.ua/search/?product_id='+product_id+'|'
              }
              if (typeSite == 'dokua'){ // only DOK.UA
                p_url = '|https://dok.ua/result2/art?id='+page_id+'|'
              }      
              if (typeSite != 'dokua' && typeSite != 'exist') {
                p_url = url
              }                      
              // console.log('staroe yslovie');
            }
          }
          console.log(typeSite)
          getInputsByValue(code)[0].value += p_url
          getInputsByValue(code)[0].setAttribute("style", "color:" + p_color);

          $('#estimate_defects_attributes_' + id + '_total_price').val(p_price);
          $('#estimate_defects_attributes_' + id + '_total_price').attr("style", "color:" + p_color);
          $('#estimate_defects_attributes_' + id + '_name').attr("style", "color:" + p_color);

         // $('html, body').animate({
         //   scrollTop: $('#estimate_defects_attributes_' + id + '_name').offset().top
         // }, 2000);
          find += 1
          //console.log('find -> ',find)
          //$('loadimage').hide();
          //console.log('-----------------------');
          if (find >= max-1){
            $('#spinner').hide('slow');
            $('#end_find_part').show('slow');
            //alert('Поиск закончен!');
           // console.log('Поиск закончен!');
          }
        }
      });
    } // end for 

  }
  else { }
}

function getInputsByValue(value) {
  var allInputs = document.getElementsByTagName("input");
  var results = [];
  for (var x = 0; x < allInputs.length; x++)
    if (allInputs[x].value.includes(value))
      results.push(allInputs[x]);
  return results;
}

function hideimg() {
  var title = document.title;
  //if (title.includes('_N')) {
  if ($('.podpis').css('display') != 'none' ){  
    document.title = title.replace('_N', '').replace('scan_', '')
  } else {
    document.title = 'scan_' + title + '_N'
  }

  var myClasses = document.querySelectorAll('.podpis'),
    i = 0,
    l = myClasses.length;
  if (myClasses[0].style.display == 'block') {
    $('#img1').hide()
    $('#img2').hide()
    $('#img3').hide()
    $('#img4').hide()
    for (i; i < l; i++) {
      myClasses[i].style.display = 'none';
    }
  } else {
    $('#img1').show()
    $('#img2').show()
    $('#img3').show()
    $('#img4').show()
    for (i; i < l; i++) {
      myClasses[i].style.display = 'block';
    }
  }
}

function UpdateCompanyName(name) {
  var spans = document.getElementsByClassName('company_name');
  for (var i = 0; i < spans.length; i++) {
    spans[i].innerHTML = name;
  };
}

function autoSearchGet() {
  var $input = $('*[data-behavior="autocomplete"]')

  $.ajax({
    url: '/autosearch',
    type: "GET",
    dataType: 'json',
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
    message: 'Запитую дані',
    data: { q: $input.val() },
    success: function (response) {
    // console.log(response[0]['name'])
     autocomplete($input[0], response, $('#order_poster_id')[0], 'users');
    }
  });

  //console.log('result!')

}

function autoSearchGetRST() {
  var $input = $('*[data-behavior="autocompleteRST"]')

  $.ajax({
    url: '/autosearch_rst',
    type: "GET",
    
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
    message: 'Запитую дані',
    data: { q: $input.val() },
    success: function (response) {
     //console.log($input.val())
     //console.log(response)     
     if (response !== null || ($('#search').val().split(' ').length <= 2)){
        autocomplete($input[0], response, $('#bullet_poster_id')[0], 'bullet');
        
     }
    }
  });

  //console.log('result!')

}

function autoSearchGetRSTforOrderForm() {
  var $input = $("#order_insurance_objects_brand")

  $.ajax({
    url: '/autosearch_rst',
    type: "GET",
    
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
    message: 'Запитую дані',
    data: { q: $input.val(), form: 'order' },
    success: function (response) {
     //console.log($input.val())
     //console.log(response)     
     if (response !== null || ($input.val().split(' ').length <= 2)){
        autocomplete($input[0], response, $('#order_poster_id')[0], 'o_form');
        
     }
    }
  });

  //console.log('result!')

}

function autocomplete(inp, arr, hidden_inp, type) {
  /*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
  var currentFocus;
  /*execute a function when someone writes in the text field:*/
 // inp.addEventListener("input", function(e) {
    if ((inp.value.split(' ').length <= 2)){
      var a, b, i, val = inp.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) { return false;}
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", inp.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      inp.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
 
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          if (type == 'users'){
            b.innerHTML = "<strong>" + arr[i]['name'].substr(0, val.length) + "</strong>";
            b.innerHTML += arr[i]['name'].substr(val.length);
          /*insert a input field that will hold the current array item's value:*/
            b.innerHTML += "<input type='hidden' value='" + arr[i]['name'] + "||"+ arr[i]['id'] +"'>";
          } else {
            b.innerHTML = "<strong style='color: #009688'>" + arr[i].substr(0, val.length) + "</strong>";
            b.innerHTML += arr[i].substr(val.length);
            b.innerHTML += "<input type='hidden' value='" + arr[i] +"'>";
          }
          /*execute a function when someone clicks on the item value (DIV element):*/
              b.addEventListener("click", function(e) {
              /*insert the value for the autocomplete text field:*/
              if (type == 'users'){
                inp.value = this.getElementsByTagName("input")[0].value.split('||')[0];
                hidden_inp.value = this.getElementsByTagName("input")[0].value.split('||')[2];
              } else {
                if (type == 'o_form'){
                  //var inpu_s = 
                  inp.value = this.getElementsByTagName("input")[0].value.split(' ')[0]
                  var aa = this.getElementsByTagName("input")[0].value.split(' ')
                  aa.shift()
                  $('#order_insurance_objects_object_model_name').val(aa.join(' '))
                } else {
                  inp.value = this.getElementsByTagName("input")[0].value+' ';
                  inp.focus()
                }
              }
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              closeAllLists();
          });
          a.appendChild(b);
         
      }
    }
 // });
  /*execute a function presses a key on the keyboard:*/
 /* inp.addEventListener("keydown", function(e) {
      var x = document.getElementById(this.id + "autocomplete-list");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        currentFocus++;
        addActive(x);
      } else if (e.keyCode == 38) { 
        currentFocus--;
        addActive(x);
      } else if (e.keyCode == 13) {
        e.preventDefault();
        if (currentFocus > -1) {
          if (x) x[currentFocus].click();
        }
      }
  }); */
/*
  function addActive(x) {
    if (!x) return false;
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = (x.length - 1);
    x[currentFocus].classList.add("autocomplete-active");
  } 
  function removeActive(x) {
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }*/
  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != inp) {
      x[i].parentNode.removeChild(x[i]);
    }
  }
}
/*execute a function when someone clicks in the document:*/
document.addEventListener("click", function (e) {
    closeAllLists(e.target);
});
}

function updateTime() {
  var months = ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня",
  "серпня", "вересня", "жовтня", "листопада", "грудня"]
  var dtString = new Date();
  var day = dtString.getDate().toString()
  var mounth = dtString.getMonth().toString()
  var year = dtString.getFullYear().toString()

  var hour = dtString.getHours().toString()
  var minute = dtString.getMinutes().toString()
  var seconds = dtString.getSeconds().toString()

  if (hour.length == 1){
    var dodhor = '0'
  } else {
    var dodhor = ''
  }

  if (minute.length == 1){
    var dodmin = '0'
  } else {
    var dodmin = ''
  }

  if (seconds.length == 1){
    var dodsec = '0'
  } else {
    var dodsec = ''
  }

  $('#datetime').html(day+' '+months[mounth]+' '+year+', '+dodhor+hour+':'+dodmin+minute+':'+dodsec+seconds);
  setTimeout(updateTime,1000);
}

function updateCommentsCount() {
  var cookie = document.cookie.split(';')
  var id = ''
  cookie.forEach((i) => { if (i.includes('userid')) { id = i.split('=')[1]} } )
  //var id = document.cookie.split(';')[1].split('=')[1]
  //console.log('id => ', id)
  $.ajax({
    url: '/comments_count_to_read',
    type: "GET",
    dataType: 'script',
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
    message: 'Запитую дані',
    data: { user_id: id},
    success: function (response) {
      //console.log(response)
      //if (response == true) {
       // console.log('response true')
      //  var homePageIntarval = setInterval(blinkerHomePage('true'), 1000);
      //} else {
       // console.log('response false')
        //clearInterval(homePageIntarval);
        //blinkerHomePage('false');
       // $('#home_page a').css('color', 'white');
      //}
    }
  });

  setTimeout(updateCommentsCount,1200000);

}

function blinkerHomePage(text) {
  if (text == 'true'){
    $('#home_page a').css('color', '#ef9a9a');
    $('#home_page a').addClass("blink_me");
  } else {
    $('#home_page a').removeClass("blink_me");
    $('#home_page a').css('color', 'white');
  }
}

function reqDefects(idEst,idOrd, Where, index){
  if (Where == 'def'){
    var ord_kind = 'ord_id_def_audahistory'
  } else {
    var ord_kind = 'ord_id_photo_audahistory'
  }

  if (idEst == 0 || idEst == '' || idEst == undefined) {
    idEst = 48261
  }

  $.ajax({
    url: '/estimates/return_uploads/'+idEst,
    type: "GET",
    dataType: 'json',
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
    message: 'Запитую дані',
    data: { ord_id_def_audahistory: idOrd },
    success: function (response) {
      //console.log(JSON.stringify(response));
      if (Where == 'def'){
        $('#padd_def'+index).show();
        $('#padd_def'+index)[0].setAttribute("data",JSON.stringify(response));
      } else {
        $('#padd1_'+index).show();
        $('#padd1_'+index)[0].setAttribute("data",JSON.stringify(response));
      }
    }
  });
}

function addPhoto_for_audahistory(input, div, li, idEst, index){
  // console.log(li);
  if (idEst =='photo'){
    var div1 = $('#'+div);
    var li1 = $('#'+li);

    var dataJson = $('#padd1_'+index)[0].getAttribute("data");
    var uploads = JSON.parse(dataJson);
    var count = Object.keys(uploads).length;
  }else{

    var div1 = $('#'+div);
    var li1 = $('#'+li);
    var input1 = $('#'+input);
        input1.hide();
  
    var dataJson = $('#padd_def'+index)[0].getAttribute("data");
    var uploads = JSON.parse(dataJson);
    var count = Object.keys(uploads).length;
    div1[0].setAttribute("style","overflow:auto;");
  
  }
  //console.log(uploads[0]);
  for (var i=0; i<count+1; i++){
    if (uploads[i] != undefined){
      if (idEst == 'photo'){
        var upl_type = uploads[i]['path'].split('.').pop().toLowerCase();
      } else {
        var upl_type = '';
      } 
    // console.log(upl_type+'///////////////////');
    //  console.log(idEst+'///////////////////');
      if ( upl_type == 'jpeg' || upl_type == 'jpg' || upl_type == 'png' || upl_type == 'gif' || upl_type == 'pdf'){
        var img = document.createElement("img");
                      // console.log(i);
                        //console.log(uploads[i]);
        img.src=uploads[i]['path'].split('public')[1];
        img.style = "width:130px; padding: 5px 5px 5px 5px;";

        li1[0].appendChild(img);

        if (idEst == 'photo'){        
          var func_to_click = 'window.open("'+img.src+'");';
        }else{
          var func_to_click = "#";
        }

        img[0].setAttribute("onclick", func_to_click); 
      } else {
        var a = document.createElement("li");
        var def_name = uploads[i]['name'].replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
        var createAText = document.createTextNode(def_name+'----'+localeRemontVozd(uploads[i]['rem_vozd']));
        if ((i >= 1) && count >= 10 ){
          if (uploads[i]['rem_vozd'] != uploads[i-1]['rem_vozd']){
            a.appendChild(document.createTextNode('*******************************************************'));
            li1[0].appendChild(a);
          } else {
            a.appendChild(createAText);
            li1[0].appendChild(a);
          }
        } else {
          a.appendChild(createAText);
          li1[0].appendChild(a);
        }

      }
    }
  }
}

function localeRemontVozd(name){
  if (name === 'snyat'){
    return 'ЗНЯТИ/ВСТАНОВИТИ'
  }
  if (name === 'remont'){
    return 'РЕМОНТ'
  }
  if (name === 'pokraska'){
    return 'ФАРБУВАННЯ'
  }
  if (name === 'zamena'){
    return 'ЗАМІНА'
  }
}

function podpisInCheck(){
  //console.log('click!');
  var pechat = document.getElementById('pechat');
  if (pechat.checked == true) { 
    //console.log('view!')
    $('#img2').show();
    $('#img3').show();
  } else {
    if ($('#img2').is(":visible") == true ){
     // console.log('hidden!')
      $('#img2').hide();
      $('#img3').hide();
    }
  }
}

function addOrderHelp(){
  $('.add_button_front').hover(function(){
    $(".ml10").show();  //Add the active class to the area is hovered
}, function () {
    $(".ml10").hide();

});
}

window.addAjaxLoaderHandler = function() {
  $(document).on('ajax:send', function() {
    $('#spinner').show();
  });

  $(document).on('ajax:complete', function(){
    setTimeout(() => {$('#spinner').hide();}, 100);
  });
}

function getOrdersInfo() {
$.ajax({
    url: '/orders_info/',
    type: "GET",
    dataType: 'json',
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
      success: function(response) {
               var f_est=response;
               //console.log(response)
               //console.log(f_est)
               $('#s_inwork').text(f_est['s_inwork_result'])
               $('#s_inwork').attr('style','background-color:'+f_est['s_inwork_color']);
               $('#s_inwork_text').attr('style','background-color:'+f_est['s_inwork_color']);

               $('#s_taken').text(f_est['s_taken_result'])
               $('#s_taken').attr('style','background-color:'+f_est['s_taken_color']);
               $('#s_taken_text').attr('style','background-color:'+f_est['s_taken_color']);

               $('#c_wait_calc').text(f_est['c_wait_calc_result'])
               $('#c_wait_calc').attr('style','background-color:'+f_est['c_wait_calc_color']);
               $('#c_wait_calc_text').attr('style','background-color:'+f_est['c_wait_calc_color']);

               $('#c_calc_nocalc').text(f_est['c_calc_nocalc_result'])
               $('#c_calc_nocalc').attr('style','background-color:'+f_est['c_calc_nocalc_color']);
               $('#c_calc_nocalc_text').attr('style','background-color:'+f_est['c_calc_nocalc_color']);
              },
      });
}

function getAgreedInfo() {
  var id_comp = '10';
  $('select[name^="companies"] option').each(function(o) {

    id_comp =this.value;
    var text = this.innerHTML

    if (id_comp != 0) {
      $.ajax({
          url: '/companies_agreed/',
          type: "GET",
          dataType: 'json',
          data: { comp_id: id_comp },
          statusCode: {
            404: function () {
              console.log("page not found");
            },
            500: function () {
              console.log("неправильний параметр");
            }
          },        
          success: function(response) {
            var f_est=response;
            //console.log(text)
            text = text + '('+f_est['agreed_count']+')'
            $('select[name^="companies"] option')[o].innerHTML = text
            $('#compSelect ul li span')[o].innerHTML = text
            //console.log(f_est)
            //console.log(text)
          },
      });
    }
});
}

function userLinkInfo(userLink,userInfo){
//  console.log('tyta');
  $('#'+userLink).hover(function(){
   // console.log('show');
    $('#'+userInfo).show();
}, function () {
    //$('#'+userInfo).hide();
});
}

function reqPhotoSemilar(carnameEst){
  //alert(sch_params);
  var car_year = $('#estimate_car_year').val()
 // console.log(carnameEst+'||'+car_year+'||no||');
  if ( $('#similar_photo').length == 0) {
        $.ajax({
          url:'/return_similar',
          type: "GET",
          dataType: 'json',
          message:'Запитую дані',
          data: { carname: carnameEst+'||'+car_year+'||no||'},
          statusCode: {
            404: function () {
              console.log("page not found");
            },
            500: function () {
              console.log("неправильний параметр");
            }
          },          
          success: function(response) {
            var res=response 
           // console.log(response);
              var count = Object.keys(res).length;
             // console.log(count);
              if ( count == 0 ) {alert('На жаль подібних авто не знайдено, можливо марка та модель ведені не корректно.'); }
              //console.log( res[1]['name']);
              //for (i = 0; i < count; i++) {
               for (var i in res) {
                  var br = document.createElement("br"); 
                  var br1 = document.createElement("br"); 
                  var br2 = document.createElement("br"); 
                  var hr = document.createElement("hr");
                  var hr1 = document.createElement("hr");
                  var hr2 = document.createElement("hr");
                  var src = document.getElementById("similar");
                  var span = document.createElement("span");                        
                  var br3 = document.createElement("br");                        
                  var img_schema = document.createElement("img");
                      img_schema.src = '/images/schema_empty.png';
                      img_schema.style = "width:160px; padding: 5px;";
                      //img_schema.setAttribute ("usemap","#planetmap");
                  var div_pr1 = document.createElement("div");
                      div_pr1.setAttribute ("class","div_pr");
                  var div_pr2 = document.createElement("div");
                      div_pr2.setAttribute ("class","div_pr");
                  var div_pr3 = document.createElement("div");
                      div_pr3.setAttribute ("class","div_pr");
                  var div_pr4 = document.createElement("div");
                      div_pr4.setAttribute ("class","div_pr");

                  var div_sch = document.createElement("div");
                        div_sch.setAttribute("class","main_shema_nil");
                        div_sch.setAttribute("id",i+"main_shema");

                  var div_fl = document.createElement("div");
                        div_fl.setAttribute ("class","div_fl");
                        div_fl.setAttribute ("id",i+"div_fl");
                  var div_f = document.createElement("div");
                        div_f.setAttribute ("class","div_f");
                        div_f.setAttribute ("id",i+"div_f");
                  var div_fr = document.createElement("div");
                        div_fr.setAttribute ("class","div_fr");
                        div_fr.setAttribute ("id",i+"div_fr");
                  var div_ff = document.createElement("div");
                        div_ff.setAttribute ("class","div_ff");
                        div_ff.setAttribute ("id",i+"div_ff");

                  var div_dfl = document.createElement("div");
                        div_dfl.setAttribute ("class","div_dfl");
                        div_dfl.setAttribute ("id",i+"div_dfl");
                  var div_dfr = document.createElement("div");
                        div_dfr.setAttribute ("class","div_dfr");
                        div_dfr.setAttribute ("id",i+"div_dfr");

                  var div_t = document.createElement("div");
                        div_t.setAttribute ("class","div_t");
                        div_t.setAttribute ("id",i+"div_t");

                  var div_drl = document.createElement("div");
                        div_drl.setAttribute ("class","div_drl");
                        div_drl.setAttribute ("id",i+"div_drl");
                  var div_drr = document.createElement("div");
                        div_drr.setAttribute ("class","div_drr");
                        div_drr.setAttribute ("id",i+"div_drr");

                  var div_rt = document.createElement("div");
                        div_rt.setAttribute ("class","div_rt");
                        div_rt.setAttribute ("id",i+"div_rt");
                  var div_rl = document.createElement("div");
                        div_rl.setAttribute ("class","div_rl");
                        div_rl.setAttribute ("id",i+"div_rl");
                  var div_rr = document.createElement("div");
                        div_rr.setAttribute ("class","div_rr");
                        div_rr.setAttribute ("id",i+"div_rr");
                  var div_r = document.createElement("div");
                        div_r.setAttribute ("class","div_r");
                        div_r.setAttribute ("id",i+"div_r");
                      
                      src.appendChild(div_sch);
                      //div_sch.appendChild(img_schema);
                      div_sch.appendChild(div_f);
                      div_sch.appendChild(div_fl);
                      div_sch.appendChild(div_ff);
                      div_sch.appendChild(div_fr);

                      div_sch.appendChild(div_pr1);

                      div_sch.appendChild(div_dfl);
                      div_sch.appendChild(div_t);
                      div_sch.appendChild(div_dfr);

                      div_sch.appendChild(div_pr2);

                      div_sch.appendChild(div_drl);
                      div_sch.appendChild(div_drr);

                      div_sch.appendChild(div_pr3);

                      div_sch.appendChild(div_rl);
                      div_sch.appendChild(div_rt);
                      div_sch.appendChild(div_rr);

                      div_sch.appendChild(div_pr4);

                      div_sch.appendChild(div_r);
                      //console.log(i);
                  for (var j in res[i]) {
                    //console.log(j)
                  
                    var img = document.createElement("img");
                    var ord_id = res[i][j]['order_id']
                    
                    img.src = res[i][j]['path'];
                    img.id = 'similar_photo'
                    img.style = "width:300px; padding: 5px;";

                     //var src = document.getElementById("similar");
                     src.appendChild(img);
                     img.setAttribute("onclick","get_defects('"+ord_id+"'); $('similar').hide();");
                     //if  (res[i][j]['name'] == 'no images!'){
                      src.appendChild(br3);
                      src.appendChild(span);
                       span.setAttribute("id","textNOimage"+i+j);
                       span.innerText = res[i][j]['schema'];
                    // }

                //$('simphoto').innerHTML = JSON.stringify(res[i][j]['order_id']);
                }
                var sch_all = res[i][j]['schema'].split('||');
                var schem_uniq = sch_all[0];
                var ord_id = sch_all[3].split('=')[1].replace(/ /g,'');
                //console.log(schem_uniq);
                //console.log('******************');
                schem_uniq.split(';').forEach(function(element) {
                  if(isNaN(element)){
                   // console.log();
                    //document.write(num1 + " is not a number <br/>");
                    $('#'+i+'div_'+element.toLowerCase()).addClass("div_"+element.toLowerCase()+'_r');
                    //console.log(i+'div_'+element.toLowerCase());
                    //console.log("div_"+element.toLowerCase()+'_r');
                   }else{
                    $('#'+i+'main_shema').addClass("main_shema_"+element);
                    //document.write(num1 + " is a number <br/>");
                    //console.log(i+'main_shema');
                    //console.log("main_shema_"+element);
                   }
                  //console.log(element);
                });

                //console.log(sch_all);

                src.appendChild(br);
                src.appendChild(br1);
                src.appendChild(hr);
                src.appendChild(hr1);
                src.appendChild(hr2);
                src.appendChild(br2);

              }
            }
           });
}
}

function get_defects(ord_id){

    $('.add-button a').click();
    $.ajax({
      url:'/return_similar',
      type: "GET",
      dataType: 'json',
      message:'Запитую дані',
      data: { ordid: ord_id},
      statusCode: {
        404: function () {
          console.log("page not found");
        },
        500: function () {
          console.log("неправильний параметр");
        }
      },          
      success: function(response) {
			  var res=response; 
        //console.log('doshlo');
        //console.log(res);
        var count = Object.keys(res).length;
        var j = 0;                 
        function myLoop () {            
          setTimeout(function () {     
          // if ($('#estimate_defects_attributes_0__destroy') != null) {
            $('.optional').addClass('active');
            //console.log(res[i]['name']);
            $('.defect-rem-vozd').last().children().children().last().val(res[j]['rem_vozd']);
            $('.defect-rem-vozd').last().children().children().last().formSelect();
            $('.defect-name').last().val(res[j]['name']);
            $('.defect-total-price').last().val(res[j]['total_price']);
            $('.defect-work-price').last().val(res[j]['work_price']);
            $('.defect-paint-price').last().val(res[j]['paint_price']);
            $('.defect-put-remove').last().val(res[j]['put_remove']);

            if ( j <= count -2) {
              $('.add-button a').click();
            }
            j++;                      
            if (j < count) {
              myLoop();              
            }                      
          }, 500)
        }
        myLoop();
      }
    });
}

function convert_to_time(e) {
  // alert('Get him!')
  // console.log(e.target.id);
  var id_nym = e.target.id.match(/\d+/)[0]
  //console.log(id_nym);
  var rem_vozd_val = $('#estimate_defects_attributes_'+id_nym+'_rem_vozd').val()

  var raboti = $('#estimate_w_price').val();
  var pokraski = $('#estimate_p_price').val();

  var remont_p = $('#estimate_defects_attributes_'+id_nym+'_work_price').val();
  var pokraska_p = $('#estimate_defects_attributes_'+id_nym+'_paint_price').val();
  var snyat_p = $('#estimate_defects_attributes_'+id_nym+'_put_remove').val();

  var convert_p = $('#estimate_defects_attributes_'+id_nym+'_convert_price').val();

  //console.log(id_nym)
  //console.log(raboti);
  if (e.target.id.includes("convert_price") ){

    if (rem_vozd_val == 'snyat') {
        $('#estimate_defects_attributes_'+id_nym+'_put_remove').val(Math.round( convert_p/raboti * 100) / 100)
    }

    if (rem_vozd_val == 'pokraska') {
         $('#estimate_defects_attributes_'+id_nym+'_paint_price').val(Math.round( convert_p/pokraski * 100) / 100)
    }

    if (rem_vozd_val == 'remont' || rem_vozd_val == 'remont2' || rem_vozd_val == 'remont3' || rem_vozd_val == 'remont4') {
        $('#estimate_defects_attributes_'+id_nym+'_work_price').val(Math.round( convert_p/raboti * 100) / 100)
    }
  } else {
    $('#estimate_defects_attributes_'+id_nym+'_convert_price').val(remont_p*raboti+pokraska_p*pokraski+snyat_p*raboti)
  }
}

function convert_to_money_with_id(id) {
  // alert('Get him!')
  // console.log(e.target.id);
  var id_nym = $('#'+id).match(/\d+/)[0]
  //console.log(id_nym);
  var rem_vozd_val = $('#estimate_defects_attributes_'+id_nym+'_rem_vozd').val()

  var raboti = $('#estimate_w_price').val();
  var pokraski = $('#estimate_p_price').val();

  var remont_p = $('#estimate_defects_attributes_'+id_nym+'_work_price').val();
  var pokraska_p = $('#estimate_defects_attributes_'+id_nym+'_paint_price').val();
  var snyat_p = $('#estimate_defects_attributes_'+id_nym+'_put_remove').val();

  var convert_p = $('#estimate_defects_attributes_'+id_nym+'_convert_price').val();

  //console.log(id_nym)
  //console.log(raboti);
  if ($('#'+id).includes("convert_price") ){

    if (rem_vozd_val == 'snyat') {
        $('#estimate_defects_attributes_'+id_nym+'_put_remove').val(Math.round( convert_p/raboti * 100) / 100)
    }

    if (rem_vozd_val == 'pokraska') {
         $('#estimate_defects_attributes_'+id_nym+'_paint_price').val(Math.round( convert_p/pokraski * 100) / 100)
    }

    if (rem_vozd_val == 'remont' || rem_vozd_val == 'remont2' || rem_vozd_val == 'remont3' || rem_vozd_val == 'remont4') {
        $('#estimate_defects_attributes_'+id_nym+'_work_price').val(Math.round( convert_p/raboti * 100) / 100)
    }
  } else {
    $('#estimate_defects_attributes_'+id_nym+'_convert_price').val(remont_p*raboti+pokraska_p*pokraski+snyat_p*raboti)
  }
}

function convert_to_time_onchange(id, valChange) {
  var id_nym = id.match(/\d+/)[0]
  //console.log(id_nym);
  var rem_vozd_val = $('#estimate_defects_attributes_'+id_nym+'_rem_vozd').val()

  var raboti = $('#estimate_w_price').val();
  var pokraski = $('#estimate_p_price').val();

  var remont_p = $('#estimate_defects_attributes_'+id_nym+'_work_price').val();
  var pokraska_p = $('#estimate_defects_attributes_'+id_nym+'_paint_price').val();
  var snyat_p = $('#estimate_defects_attributes_'+id_nym+'_put_remove').val();

  $('#estimate_defects_attributes_'+id_nym+'_convert_price').val(valChange)
  var convert_p = valChange//$('#estimate_defects_attributes_'+id_nym+'_convert_price').val();

  //console.log(id_nym)
  //console.log(convert_p);
  if (id.includes("convert_price") ){
   // console.log('mi tyt');
    if (rem_vozd_val == 'snyat') {
        $('#estimate_defects_attributes_'+id_nym+'_put_remove').val(Math.round( convert_p/raboti * 100) / 100)
    }

    if (rem_vozd_val == 'pokraska') {
         $('#estimate_defects_attributes_'+id_nym+'_paint_price').val(Math.round( convert_p/pokraski * 100) / 100)
    }

    if (rem_vozd_val == 'remont' || rem_vozd_val == 'remont2' || rem_vozd_val == 'remont3' || rem_vozd_val == 'remont4') {
        $('#estimate_defects_attributes_'+id_nym+'_work_price').val(Math.round( convert_p/raboti * 100) / 100)
    }
  } else {
    $('#estimate_defects_attributes_'+id_nym+'_convert_price').val(remont_p*raboti+pokraska_p*pokraski+snyat_p*raboti)
  }
}

function paintNormal(){
  //$('select[name^="companies"] option')
  var remSelect = $('.fieldset');
  var degreeDamage = $('#normalDamageText').val()
  //if (degreeDamage == ''){degreeDamage = 1}
  //remSelect = $('.defect-rem-vozd');
  remSelect.each(function(i){
    var selectedValue = remSelect[i].querySelector('select').value
    if (selectedValue == 'pokraska') {
      convert_to_time_onchange(remSelect[i].querySelector('.defect-convert-price').id, $('#normalPaintText').val());
    }
    if (selectedValue == 'remont' && degreeDamage != '') {
      var res = getRandomArbitrary(1,2) + Math.random()*degreeDamage
      remSelect[i].querySelector('.defect-work-price').value = Math.round(res * 100) / 100
      convert_to_money_with_id(remSelect[i].querySelector('.defect-work-price').id)
    }
    if (selectedValue == 'remont2' && degreeDamage != '') {
      var res = getRandomArbitrary(2,4) + Math.random()*degreeDamage
      remSelect[i].querySelector('.defect-work-price').value = Math.round(res * 100) / 100
      convert_to_money_with_id(remSelect[i].querySelector('.defect-work-price').id)
    }
    if (selectedValue == 'remont3' && degreeDamage != '') {
      var res = getRandomArbitrary(4,8) + Math.random()*degreeDamage
      remSelect[i].querySelector('.defect-work-price').value = Math.round(res * 100) / 100
      convert_to_money_with_id(remSelect[i].querySelector('.defect-work-price').id)
    }
    if (selectedValue == 'remont4' && degreeDamage != '') {
      var res = getRandomArbitrary(8,12) + Math.random()*degreeDamage
      remSelect[i].querySelector('.defect-work-price').value = Math.round(res * 100) / 100
      convert_to_money_with_id(remSelect[i].querySelector('.defect-work-price').id)
    }            
  })
 resultof();
}

function promUAkey(zEvent) {
  var keyStr = ["Control", "Shift", "Alt", "Meta"].includes(zEvent.key) ? "" : zEvent.key + " ";
  var reportStr   =
      "The " +
      ( zEvent.ctrlKey  ? "Control " : "" ) +
      ( zEvent.shiftKey ? "Shift "   : "" ) +
      ( zEvent.altKey   ? "Alt "     : "" ) +
      ( zEvent.metaKey  ? "Meta "    : "" ) +
      keyStr + "key was pressed."
  ;
  //console.log(reportStr)
  if ( reportStr == 'The Control Shift P key was pressed.'){
    var part = zEvent.target.value
    var car = $('#brand').text().split('||')[0] +' '+ $('#brand').text().split('||')[1]+' '
    var url = "https://prom.ua/search?search_term="+car+part
    var newWin = window.open(url);
    newWin.focus();
  }
}

// Builds the HTML Table out of myList.
function buildHtmlTable(selector, myList) {
  var columns = addAllColumnHeaders(myList, selector);

  for (var i = 0; i < myList.length; i++) {
    var row$ = $('<tr/>');
    for (var colIndex = 0; colIndex < columns.length; colIndex++) {
      var cellValue = myList[i][columns[colIndex]];
      if (cellValue == null) cellValue = "";
      row$.append($('<td/>').html(cellValue));
      row$.attr('style', 'cursor: pointer;');
      row$.attr('onclick', 'setInsFromCLient(this);'); 
    }
    $(selector).append(row$);
  }
}

// Adds a header row to the table and returns the set of columns.
// Need to do union of keys from all records as some records may not contain
// all records.
function addAllColumnHeaders(myList, selector) {
  var columnSet = [];
  var headerTr$ = $('<tr/>');

  for (var i = 0; i < myList.length; i++) {
    var rowHash = myList[i];
    for (var key in rowHash) {
      if ($.inArray(key, columnSet) == -1) {
        //console.log(key)
        if (key == 'id' || key == 'description' || key == 'created_at' || key == 'updated_at' || key == 'client_id' || key == 'sum_damage' || 
            key == 'sum_payment' || key == 'orders_count' || key == 'company_id' ) {

        } else {
          columnSet.push(key);
          if (key == 'policy' ){ headerTr$.append($('<th/>').html('Страх. догов.')); }
          if (key == 'brand' ){ headerTr$.append($('<th/>').html('Марка')); }
          if (key == 'object_model_name' ){headerTr$.append($('<th/>').html('Модель')); }
          if (key == 'nomer' ){ headerTr$.append($('<th/>').html('Держ. номер.'));}
          if (key == 'object_type' ){ headerTr$.append($('<th/>').html('Тип обєкту'));}
          
        }
      }
    }
  }
  $(selector).append(headerTr$);

  return columnSet;
}

function setInsFromCLient(tr){
  var dogovor = tr.getElementsByTagName("td")[0].innerHTML
  var obj_type = tr.getElementsByTagName("td")[1].innerHTML
  var brand = tr.getElementsByTagName("td")[2].innerHTML
  var model = tr.getElementsByTagName("td")[3].innerHTML
  var nomer = tr.getElementsByTagName("td")[4].innerHTML
  
  $('#order_insurance_objects_policy').val(dogovor);
  $('#order_insurance_objects_policy').attr('readonly', 'true')

  $('#order_insurance_objects_brand').val(brand);
  $('#order_insurance_objects_brand').attr('readonly', 'true')

  $('#order_insurance_objects_object_model_name').val(model);
  $('#order_insurance_objects_object_model_name').attr('readonly', 'true')

  $('#order_insurance_objects_nomer').val(nomer);
  $('#order_insurance_objects_nomer').attr('readonly', 'true')

  $('#order_insurance_objects_object_type').val(obj_type)
  $('#order_insurance_objects_object_type').attr('disabled', 'true')
  $('#order_insurance_objects_object_type').formSelect();

  $('#ins_list_div').toggle();
}

function magnify(imgID, zoom) {
  var img, glass, w, h, bw;
  img = document.getElementById(imgID);
  /*create magnifier glass:*/
  glass = $('#mignifier-div')[0]
  //glass = document.createElement("DIV");
  //glass.setAttribute("class", "img-magnifier-glass");
  /*insert magnifier glass:*/
  //img.parentElement.insertBefore(glass, img);
  /*set background properties for the magnifier glass:*/
  glass.style.backgroundImage = "url('" + img.parentElement.href + "')";
  glass.style.backgroundRepeat = "no-repeat";
  glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
  bw = 3;
  w = glass.offsetWidth / 2;
  h = glass.offsetHeight / 2;
  /*execute a function when someone moves the magnifier glass over the image:*/
  //glass.addEventListener("mousemove", moveMagnifier);
  img.addEventListener("mousemove", moveMagnifier);
  /*and also for touch screens:*/
  //glass.addEventListener("touchmove", moveMagnifier);
  img.addEventListener("touchmove", moveMagnifier);

  function moveMagnifier(e) {
    var pos, x, y;
    /*prevent any other actions that may occur when moving over the image*/
    e.preventDefault();
    /*get the cursor's x and y positions:*/
    pos = getCursorPos(e);
    x = pos.x;
    y = pos.y;
    /*prevent the magnifier glass from being positioned outside the image:*/
    if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
    if (x < w / zoom) {x = w / zoom;}
    if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
    if (y < h / zoom) {y = h / zoom;}
    /*set the position of the magnifier glass:*/
   // glass.style.left = (x - w) + "px";
   // glass.style.top = (y - h) + "px";
    /*display what the magnifier glass "sees":*/
    glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
  }
  
  function getCursorPos(e) {
    var a, x = 0, y = 0;
    e = e || window.event;
    /*get the x and y positions of the image:*/
    a = img.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return {x : x, y : y};
  }
}

function RemoveGreenUrlFromDefects(){
  var r = confirm("Видалити всі салатові посилання?");

  if (r == true) {  
    $('.defect-name').each(function( index ) {
      if ($(this).val().includes('http') && this.style.color == 'chartreuse') {
        var indEnd = $( this ).val().indexOf('|')
        //console.log( index + ": " + $( this ).val().substr(0,indEnd) );
        $(this).val($( this ).val().substr(0,indEnd))
      }
    });
  }
}

function RemoveAllUrlFromDefects(){
  var r = confirm("Видалити всі посилання?");

  if (r == true) {  
    $('.defect-name').each(function( index ) {
      var indEnd = $( this ).val().indexOf('|')
      //console.log( index + ": " + $( this ).val().substr(0,indEnd) );
      //console.log(indEnd);
      if (indEnd != -1){
        $(this).val($( this ).val().substr(0,indEnd))
      }
    });
  }
}

function rstCalc(id){
  var raw_price = $('#raw_price_'+id).text();
  var price = $('#price_'+id).text();
  var cor_all = $('#cor_all_'+id).val();
  var cor_year = $('#cor_year_'+id).val();
  var cor_compl = $('#cor_compl_'+id).val();
  var cor_sell = $('#cor_sell_'+id).val();

  cor_all = parseFloat(cor_year) + parseFloat(cor_compl) + parseFloat(cor_sell)
  $('#cor_all_'+id).text(cor_all)
  if (cor_all > 0) {
    price = raw_price * ((cor_all/100) + 1)
  } else {
    price = (raw_price / ((cor_all/100) - 1))*(-1)
  }
  
  $('#price_'+id).text(Math.round(price * 100) / 100)
  
  var avarage_price
  var sum = 0
  //$('.rst-price-car').each(
   // (i)=>{
    for (let i = 0; i <= $('.rst-price-car').length; i++) { 
      //console.log(parseFloat($('#price_'+parseFloat(i+1)).text(), 10 ))
      if (isNaN(parseFloat($('#price_'+parseFloat(i+1)).text(), 10 ))){

      } else {
        sum += parseFloat($('#price_'+parseFloat(i+1)).text(), 10 )
      }
     // console.log(i)
    }
  //})
  
  //console.log('===')
  //console.log($('.rst-price-car').length)
 // console.log(sum)
  avarage_price = sum / $('.rst-price-car').length

  $('#res_sum').text($('.rst-price-car').length)

  $('#sred_price').text(Math.round(avarage_price * 100) / 100)
}

function ChangeAutocomplitForBuletSearch(){
 if ($('#rst').is(":checked")) {
  $('input#search').attr('data-behavior', 'autocompleteRST')
 } else {
  $('input#search').attr('data-behavior', '')
 }
}

function checkBid() {
  var minimal_price = parseFloat($('#minimal_price').text());
  var minimal_up = parseFloat($('#minimal_up').text());
  var bid = parseFloat($('#input_bid').val());
  var last_bid = parseFloat($('#last_bid').text());

  var auctionType= $('#auction_type')[0].getAttribute('data');

  if (isNaN(last_bid)){
    if (auctionType == 'remont'){
      last_bid = minimal_price 
    } else {
      last_bid = 1000 
    }
  }
  //  console.log(isNaN(last_bid))
 //   console.log(last_bid)
 //  console.log(auctionType)
 // console.log(bid)

  if (auctionType == 'remont'){
    if (bid <= (minimal_price - minimal_up) && bid >= minimal_up && bid <= (last_bid - minimal_up) && bid >= (last_bid/2)){
      $('#bid_submit').prop('disabled', '')
      $('#bid_submit').attr('data-confirm', 'Ви впевнені? Ваша ставка ' + $('#input_bid').val() + ' грн.')
    } else {
      $('#bid_submit').prop('disabled', 'true')
    }
  } else {
    if ( bid > (last_bid/10)){
      $('#bid_check').text('')
      $('#bid_submit').prop('disabled', '')
      $('#bid_submit').attr('data-confirm', 'Ви впевнені? Ваша ставка ' + $('#input_bid').val() + ' грн.')
    } else {
      if (last_bid != 0){
        $('#bid_check').text('мін. '+ Math.round(( last_bid/10 + 1) * 100) / 100);
      }
      $('#bid_submit').prop('disabled', 'true')
    }
  }


}

function vinInfoText() {
  var vin = $('#estimate_car_vincode').val()
  $.ajax({
    url:'/vin_info_text',
    type: "GET",
    dataType: 'json',
    message:'Запитую дані',
    data: { vincode: vin},
    statusCode: {
      404: function () {
        console.log("page not found");
        alert('Информация отсутствует!');
      },
      500: function () {
        console.log("неправильний параметр");
        alert('Информация отсутствует!');
      }
    },          
    success: function(response) {
      alert(response.join("\n"))
      $('#estimate_notes')[0].value += "\n"+response.join("\n")
    }
  })
}

function InfoTextfromVin() {
  var nomer = $('#brand').text().split('||')[2]
  var newWin1 = window.open("https://ua.carplates.app/number/" + nomer);
  newWin1.focus();
}

function editPage(el) {
  document.body.contentEditable = el.checked;
  if(el.checked){
    alert('Тепер можна редагувати звіт прямо на сторінці!');
  } else {
    alert('Редагування скасовано!');
  }
  
}

function checkboxEditor(mainUrl, attr, callbackElementId, message){
  $.ajax({
    url: mainUrl,
    type: "PUT",
    dataType: 'json',
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
    message: 'Запитую дані',
    data: attr,
    success: function (response) {
      //console.log(response == true)
      if (response == true){
        $(callbackElementId).val(message);
        $(callbackElementId).text(message);
      } else {
        $(callbackElementId).val('');
        $(callbackElementId).text('');
      }

    }
  });
}

function AnswerPosterComment(id_comment, poster, poster_id){
  var content = $('#comment_'+id_comment+' .card-content')[0].innerText
  console.log(content)
  $('#comment_content').val(
  '<figure class="quote"><blockquote>'+content+'</blockquote><figcaption>&mdash; '+poster+'</figcaption> </figure>\n\n'
  );
  $('#comment_recipient_id').val(poster_id);
  $('#comment_recipient_id').formSelect();
  document.getElementById("comment_content").focus();
}

function showHideLogForArtem(){
  $('#logs-view').toggle('slow')
  
  var x = document.getElementById("logs-toggle");
  if (x.innerHTML === "chevron_right") {
    x.innerHTML = "chevron_left";
  } else {
    x.innerHTML = "chevron_right";
  }
}

function showHideImpotantForArtem(){
  $('#impotants-view').toggle('slow')
  
  var x = document.getElementById("impotans-toggle");
  if (x.innerHTML === "add_circle") {
    x.innerHTML   = "cancel";
    x.style.color = "#f44336"
  } else {
    x.innerHTML  = "add_circle";
    x.style.color = "#009688"
  }  
}

function catalogSelectGroupe(id){
  $.ajax({ 
    url: "/catalog_select", 
    method: "GET", 
    data: {"id_src": id},
    success: function(data) {
      setColorAllSelectPart();
      if( id == 'catalog_basic'){
        ColorPartBasicCatalog();
      }
      
    } 
   
});
}

function SelectOperationButton(e,divid, partsName){

  var datan = document.querySelector('[title="'+partsName+'"]').getAttribute('datan')

  RemoveButtonsCatalog();

  var left  = e.clientX  + "px";
  var top  = e.clientY  + "px";

  var div = document.getElementById(divid);

  div.style.left = left;
  div.style.top = top;
  $("#parts-name").text(partsName)
  $("#datan").text(datan)

  $("#"+divid).show();

  CheckColorPartAndButton(partsName)

  return false;
}

function CheckColorPartAndButton(partsName){
  var partFind = 0
   $('#btn-lev')[0].removeAttribute('disabled')
   $('#btn-prav')[0].removeAttribute('disabled')
  //console.log(partsName)
  if (partsName.includes(' Л')){
    $('#btn-lev').attr("data", "true")
    $('#btn-lev').attr('style', 'background-color: #f44336')
  } else {
    $('#btn-lev').attr('data', 'false')
    $('#btn-lev').attr('style', 'background-color: #009688')
  }
  if (partsName.includes(' ПР')){
    $('#btn-prav').attr("data", "true")
    $('#btn-prav').attr('style', 'background-color: #f44336')
  } else {
    $('#btn-prav').attr('data', 'false')
    $('#btn-prav').attr('style', 'background-color: #009688')
  }

  if (!partsName.includes(' ПР') && !partsName.includes(' Л')){ 
    $('#btn-prav').attr('disabled', 'true')
    $('#btn-lev').attr('disabled', 'true')
  }


  for (var i = 0; i < $('.defect-name').length; i++) {
    var partName = $('.defect-name')[i].value
    setColorSelectPart(partName.split('(')[0])
    //console.log(partName == partsName)
    if (partName == partsName && $('.fieldset')[i].style.display != 'none' ) {
       partFind = 1
       var part = $('.fieldset')[i].querySelector('select')
      if (part.value == 'zamena'){
        $('#btn-e').attr("data", "true")
        $('#btn-e').attr('style', 'background-color: #f44336')
      } 
      if (part.value == 'remont'){
        $('#btn-i').attr("data", "true")
        $('#btn-i').attr('style', 'background-color: #f44336')
      } 
      if (part.value == 'remont2'){
        $('#btn-i2').attr("data", "true")
        $('#btn-i2').attr('style', 'background-color: #f44336')
      } 
      if (part.value == 'remont3'){
        $('#btn-i3').attr("data", "true")
        $('#btn-i3').attr('style', 'background-color: #f44336')
      } 
      if (part.value == 'remont4'){
        $('#btn-i4').attr("data", "true")
        $('#btn-i4').attr('style', 'background-color: #f44336')
      }                   
      if (part.value == 'pokraska'){
        $('#btn-l').attr("data", "true")
        $('#btn-l').attr('style', 'background-color: #f44336')
      } 
      if (part.value == 'snyat'){
        $('#btn-n').attr("data", "true")
        $('#btn-n').attr('style', 'background-color: #f44336')
      } 
      if (part.value == 'proverka'){
        $('#btn-p').attr("data", "true")
        $('#btn-p').attr('style', 'background-color: #f44336')
      }
    } else {
      if (partFind != 1){
        $('#btn-e').attr("data", "false")
        $('#btn-e').attr('style', 'background-color: #009688')
        $('#btn-i').attr("data", "false")
        $('#btn-i').attr('style', 'background-color: #009688')
        $('#btn-i2').attr("data", "false")
        $('#btn-i2').attr('style', 'background-color: #009688')        
        $('#btn-i3').attr("data", "false")
        $('#btn-i3').attr('style', 'background-color: #009688')        
        $('#btn-i4').attr("data", "false")
        $('#btn-i4').attr('style', 'background-color: #009688')
        $('#btn-l').attr("data", "false")
        $('#btn-l').attr('style', 'background-color: #009688')
        $('#btn-n').attr("data", "false")
        $('#btn-n').attr('style', 'background-color: #009688')
        $('#btn-p').attr("data", "false")
        $('#btn-p').attr('style', 'background-color: #009688')
      }    
    }
  }
}

function setButtonCatalog(id){
  //console.log(id.includes('lev'))
  if ($('#'+id).attr('data') == 'false') {
    $('#'+id).attr('data', 'true')
    $('#'+id).attr('style', 'background-color: #f44336')
    if (id.includes('lev') || id.includes('prav')) {
      if (id.includes('lev')) {
        $('#parts-name').text($('#parts-name').text().replace(' ПР',''))
        $('#parts-name').text($('#parts-name').text() + ' Л')
        $('#btn-prav').attr('data', 'false')
        $('#btn-prav').attr('style', 'background-color: #009688')
        //console.log('check left')
        CheckColorPartAndButton($('#parts-name').text());
      } else {
        $('#parts-name').text($('#parts-name').text().replace(' Л',''))
        $('#parts-name').text($('#parts-name').text() + ' ПР')
        $('#btn-lev').attr('data', 'false')
        $('#btn-lev').attr('style', 'background-color: #009688')
        CheckColorPartAndButton($('#parts-name').text());
        //console.log('check right')
      }
    } 
  } else {
    $('#'+id).attr('data', 'false')
    $('#'+id).attr('style', 'background-color: #009688')
    if (id.includes('lev') || id.includes('prav')) {
      if (id.includes('lev')) {
        $('#parts-name').text($('#parts-name').text().replace(' Л',''))
      } else {
        $('#parts-name').text($('#parts-name').text().replace(' ПР',''))
      }
    }     
  }

  if ($('#btn-e').attr('data') == 'true') {
    $('#btn-i').attr('disabled', 'true')
    $('#btn-i2').attr('disabled', 'true')
    $('#btn-i3').attr('disabled', 'true')
    $('#btn-i4').attr('disabled', 'true')
  } else {
    $('#btn-i')[0].removeAttribute('disabled')
    $('#btn-i2')[0].removeAttribute('disabled')
    $('#btn-i3')[0].removeAttribute('disabled')
    $('#btn-i4')[0].removeAttribute('disabled')
  }

  if ($('#btn-i').attr('data') == 'true' || $('#btn-i2').attr('data') == 'true' || $('#btn-i3').attr('data') == 'true' || $('#btn-i4').attr('data') == 'true') {
    $('#btn-e').attr('disabled', 'true')
    if ($('#btn-i').attr('data') == 'true') {
      $('#btn-i2').attr('disabled', 'true')
      $('#btn-i3').attr('disabled', 'true')
      $('#btn-i4').attr('disabled', 'true')
    }
    if ($('#btn-i2').attr('data') == 'true') {
      $('#btn-i').attr('disabled', 'true')
      $('#btn-i3').attr('disabled', 'true')
      $('#btn-i4').attr('disabled', 'true')
    }
    if ($('#btn-i3').attr('data') == 'true') {
      $('#btn-i2').attr('disabled', 'true')
      $('#btn-i').attr('disabled', 'true')
      $('#btn-i4').attr('disabled', 'true')
    }
    if ($('#btn-i4').attr('data') == 'true') {
      $('#btn-i2').attr('disabled', 'true')
      $('#btn-i3').attr('disabled', 'true')
      $('#btn-i').attr('disabled', 'true')
    }       
  } else {
    if ($('#btn-e').attr('data') == 'false'){
      $('#btn-e')[0].removeAttribute('disabled')
      if ($('#btn-i').attr('data') == 'false') {
        $('#btn-i2')[0].removeAttribute('disabled')
        $('#btn-i3')[0].removeAttribute('disabled')
        $('#btn-i4')[0].removeAttribute('disabled')
      }
      if ($('#btn-i2').attr('data') == 'false') {
        $('#btn-i')[0].removeAttribute('disabled')
        $('#btn-i3')[0].removeAttribute('disabled')
        $('#btn-i4')[0].removeAttribute('disabled')
      }
      if ($('#btn-i3').attr('data') == 'false') {
        $('#btn-i2')[0].removeAttribute('disabled')
        $('#btn-i')[0].removeAttribute('disabled')
        $('#btn-i4')[0].removeAttribute('disabled')
      }
      if ($('#btn-i4').attr('data') == 'false') {
        $('#btn-i2')[0].removeAttribute('disabled')
        $('#btn-i3')[0].removeAttribute('disabled')
        $('#btn-i')[0].removeAttribute('disabled')
      }  
    }
      
  }


}

function addCatalogSign(singer){
  $('.add-button a').click(); resultof();
  $('.defect-name').last().val(singer);
  $('.defect-sign-image').last().val($('#canvas-sign')[0].toDataURL());
  $('.defect-rem-vozd').last().children().children().last().val($('#client-choose').val());
  $('.defect-rem-vozd').last().children().children().last().formSelect(); 
}

function addCatalogSignGuset(singer, est_id){
  var defect_name = singer;
  var defect_sign_image = $('#canvas-sign')[0].toDataURL();
  var defect_rem_vozd = $('#client-choose').val();
 
  var def_update_params = defect_name+'||'+defect_sign_image+'||'+defect_rem_vozd
//  console.log(est_id)
  $.ajax({
    url: '/put_defect/',
    type: "PUT",
    dataType: 'json',
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
    message: 'Запитую дані',
    data: { id: est_id, defects_up: def_update_params},
  });
}

function addCatalogPart(partName){
  var datan = $('#datan').text()
  for (var i = 0; i < $('.defect-name').length; i++) {
    if ($('.defect-name')[i].value == partName){
      $('.def_button')[i].click();
    }
  }
  
  var ii = []
  if ($('#btn-e').attr('data') == 'true'){ ii.push('e') }
  if ($('#btn-i').attr('data') == 'true'){ ii.push('i') }
  if ($('#btn-i2').attr('data') == 'true'){ ii.push('i2') }
  if ($('#btn-i3').attr('data') == 'true'){ ii.push('i3') }
  if ($('#btn-i4').attr('data') == 'true'){ ii.push('i4') }
  if ($('#btn-l').attr('data') == 'true'){ ii.push('l') }
  if ($('#btn-n').attr('data') == 'true'){ ii.push('n') }
  if ($('#btn-p').attr('data') == 'true'){ ii.push('p') }

  if (ii.length >= 1) {
    $('.add-button a').click(); resultof();
    
    //console.log(ii.length)
    for (var i = 0; i < ii.length; i++) {
      if (ii[i] == 'e'){
          $('.defect-name').last().val(partName.trim());
          $('.defect-rem-vozd').last().children().children().last().val('zamena');
          $('.defect-rem-vozd').last().children().children().last().formSelect();
          if (i <= ii.length - 2) {
          $('.add-button a').click(); resultof();
          }
        }
        if (ii[i] == 'i'){
          $('.defect-name').last().val(partName.trim());
          $('.defect-rem-vozd').last().children().children().last().val('remont');
          $('.defect-rem-vozd').last().children().children().last().formSelect();
          if (i <= ii.length - 2) {
            $('.add-button a').click(); resultof();
          }
        }
        if (ii[i] == 'i2'){
          $('.defect-name').last().val(partName.trim());
          $('.defect-rem-vozd').last().children().children().last().val('remont2');
          $('.defect-rem-vozd').last().children().children().last().formSelect();
          if (i <= ii.length - 2) {
            $('.add-button a').click(); resultof();
          }
        }   
        if (ii[i] == 'i3'){
          $('.defect-name').last().val(partName.trim());
          $('.defect-rem-vozd').last().children().children().last().val('remont3');
          $('.defect-rem-vozd').last().children().children().last().formSelect();
          if (i <= ii.length - 2) {
            $('.add-button a').click(); resultof();
          }
        }  
        if (ii[i] == 'i4'){
          $('.defect-name').last().val(partName.trim());
          $('.defect-rem-vozd').last().children().children().last().val('remont4');
          $('.defect-rem-vozd').last().children().children().last().formSelect();
          if (i <= ii.length - 2) {
            $('.add-button a').click(); resultof();
          }
        }                   
        if (ii[i] == 'l'){
          $('.defect-name').last().val(partName.trim());
          $('.defect-rem-vozd').last().children().children().last().val('pokraska');
          $('.defect-rem-vozd').last().children().children().last().formSelect();
          if (i <= ii.length - 2) {
            $('.add-button a').click(); resultof();
          }
        }
        if (ii[i] == 'n'){
          $('.defect-name').last().val(partName.trim());
          $('.defect-rem-vozd').last().children().children().last().val('snyat');
          $('.defect-rem-vozd').last().children().children().last().formSelect();
          $('.defect-put-remove').last().val(datan);
          if (i <= ii.length - 2) {
            $('.add-button a').click(); resultof();
          }
        }  
        if (ii[i] == 'p'){
          $('.defect-name').last().val(partName.trim());
          $('.defect-rem-vozd').last().children().children().last().val('proverka');
          $('.defect-rem-vozd').last().children().children().last().formSelect();
          if (i <= ii.length - 2) {
            $('.add-button a').click(); resultof();
          }
        }                         
      }

      setColorSelectPart(partName);
    }
    RemoveButtonsCatalog();
    resultof();
  }

function setColorSelectPart(partName){
  //console.log(partName)
  var main = document.querySelector('[title="'+partName+'"]')
  if (main == null){

  } else {
    var colored_el = main.querySelector('.xer80')
    colored_el.setAttribute('style', 'fill:#4db6ac;stroke-width: 5; stroke: #4db6ac;')
  }
}
function setColorAllSelectPart(){
  //console.log(partName)
  for (var i = 0; i < $('.defect-name').length; i++) {
    var partName = $('.defect-name')[i].value
    var main = document.querySelector('[title="'+partName+'"]')
    if (main == null){

    } else {
      var colored_el = main.querySelector('.xer80')
      colored_el.setAttribute('style', 'fill:#4db6ac;stroke-width: 5; stroke: #4db6ac;')
    }
  }
}

function RemoveButtonsCatalog(){
  $('#btn-e').attr('data', 'false')
  $('#btn-e').attr('style', 'background-color: #009688')
  $('#btn-i').attr('data', 'false')
  $('#btn-i').attr('style', 'background-color: #009688')
  $('#btn-i2').attr('data', 'false')
  $('#btn-i2').attr('style', 'background-color: #009688')  
  $('#btn-i3').attr('data', 'false')
  $('#btn-i3').attr('style', 'background-color: #009688')  
  $('#btn-i4').attr('data', 'false')
  $('#btn-i4').attr('style', 'background-color: #009688')  
  $('#btn-l').attr('data', 'false')
  $('#btn-l').attr('style', 'background-color: #009688')
  $('#btn-n').attr('data', 'false')
  $('#btn-n').attr('style', 'background-color: #009688')
  $('#btn-p').attr('data', 'false')
  $('#btn-p').attr('style', 'background-color: #009688')

  $('#btn-e')[0].removeAttribute('disabled')
  $('#btn-n')[0].removeAttribute('disabled')
  $('#btn-l')[0].removeAttribute('disabled')
  $('#btn-p')[0].removeAttribute('disabled')

  $('#btn-i')[0].removeAttribute('disabled')
  $('#btn-i2')[0].removeAttribute('disabled')
  $('#btn-i3')[0].removeAttribute('disabled')
  $('#btn-i4')[0].removeAttribute('disabled')

  $('#operation-button').hide();
}

function CheckPasswordCatalog(){
  var psw = $('#catalog-psw').val()
  //console.log('--'+psw)
  if(psw == 'xthysqHtdjkdth') {
    $('#view-catalog').show();
    $('#check-catalog-psw').hide();
    ColorPartBasicCatalog();
  }
}

function ColorPartBasicCatalog(){
  for (var i = 0; i < $('.defect-name').length; i++) {
    var title_code = $('.defect-name')[i].value.split(']')[0]+']'
    if (title_code.includes('[')) {
      var basic_groupe_find = document.querySelector('[data-gruope="'+title_code+'"]')
      basic_groupe_find.querySelector('.xer80').setAttribute('style', 'fill:#4db6ac;stroke-width: 5; stroke: #4db6ac;') 
    }
  }
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

function orderFormToggle(label_marka_text, label_model_text, label_gosnomer_text){
  var type = $('#order_insurance_objects_object_type').val();
  var nomer = $('#order_insurance_objects_policy').val();
  if (nomer == ''){
    nomer = $('#order_insurance_number').val();
  }
  if (nomer == ''){
    nomer = Math.random().toString(36).slice(-8);
  }
  if ( type == 'estate' || type == 'lorry' || type == 'special') {
    $('#order_insurance_objects_brand')[0].previousSibling.innerText  = 'Тип обєкту нерухомості/обладнання/товару/'
    
    $('#order_insurance_objects_object_model_name')[0].previousSibling.innerText  = 'Нерухомiсть'
    $('#order_insurance_objects_object_model_name').val(nomer)
    $('#order_insurance_objects_object_model_name').attr('readonly', 'true')

    $('#order_insurance_objects_nomer')[0].previousSibling.innerText  = 'Нерухомiсть'
    $('#order_insurance_objects_nomer').val(nomer)
    $('#order_insurance_objects_nomer').attr('readonly', 'true')    
  } else {
    $('#order_insurance_objects_brand')[0].previousSibling.innerText  = label_marka_text

    $('#order_insurance_objects_object_model_name')[0].previousSibling.innerText  = label_model_text
    $('#order_insurance_objects_object_model_name')[0].removeAttribute('readonly')
    $('#order_insurance_objects_object_model_name').val('')

    $('#order_insurance_objects_nomer')[0].previousSibling.innerText  = label_gosnomer_text
    $('#order_insurance_objects_nomer').val('')
    $('#order_insurance_objects_nomer')[0].removeAttribute('readonly')    
  }
}

function hidePartCodeSite() {
  var text = $('#estimate_car_vincode').val()
  $('#iframe_catalog').toggle();
  //$('#frame_show').attr('src' ,'https://www.parts-catalogs.com/demo#/carInfo?q='+text)
  //$('#hide-image-view-catalog').show();
}

function detectMob() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

function setLeftForPrintAct(){
 var act_elemnts = $('.t');
 for(var i = 0; i < act_elemnts.length; i++){
   var el_left = getComputedStyle($('.t')[i])["left"];
   var el_bottom = getComputedStyle($('.t')[i])["bottom"];
   $('.t')[i].style.cssText = "left: "+(parseInt(el_left)+55)+"px; bottom: "+(parseInt(el_bottom))+"px;";
  // $('.t')[i].style.cssText = "bottom: "+(parseInt(el_bottom))+"px;";
 /* console.log("left basic => "+el_left)
  var s = (parseInt(el_left)+55);
  console.log("left: "+s+"px;"); */
 }
 var v_el_left = getComputedStyle($('#vincode')[0])["left"];
 var v_el_bottom = getComputedStyle($('#vincode')[0])["bottom"];
 $('#vincode')[0].style.cssText = "left: "+(parseInt(v_el_left))+"px; bottom: "+(parseInt(v_el_bottom))+"px; letter-spacing: 19.12px; word-spacing: 0.01px;"
}

function transliterate(word){
  var answer = ""
    , a = {};

 a["Ё"]="YO";a["Й"]="I";a["Ц"]="TS";a["У"]="U";a["К"]="K";a["Е"]="E";a["Н"]="N";a["Г"]="G";a["Ш"]="SH";a["Щ"]="SCH";a["З"]="Z";a["Х"]="H";a["Ъ"]="'";
 a["ё"]="yo";a["й"]="i";a["ц"]="ts";a["у"]="u";a["к"]="k";a["е"]="e";a["н"]="n";a["г"]="g";a["ш"]="sh";a["щ"]="sch";a["з"]="z";a["х"]="h";a["ъ"]="'";
 a["Ф"]="F";a["Ы"]="I";a["В"]="V";a["А"]="A";a["П"]="P";a["Р"]="R";a["О"]="O";a["Л"]="L";a["Д"]="D";a["Ж"]="ZH";a["Э"]="E";
 a["ф"]="f";a["ы"]="i";a["в"]="v";a["а"]="a";a["п"]="p";a["р"]="r";a["о"]="o";a["л"]="l";a["д"]="d";a["ж"]="zh";a["э"]="e";
 a["Я"]="Ya";a["Ч"]="CH";a["С"]="S";a["М"]="M";a["И"]="I";a["Т"]="T";a["Ь"]="'";a["Б"]="B";a["Ю"]="YU";
 a["я"]="ya";a["ч"]="ch";a["с"]="s";a["м"]="m";a["и"]="i";a["т"]="t";a["ь"]="'";a["б"]="b";a["ю"]="yu";

 for (var i in word){
   if (word.hasOwnProperty(i)) {
     if (a[word[i]] === undefined){
       answer += word[i];
     } else {
       answer += a[word[i]];
     }
   }
 }
 return answer;
}

function uploadExcel() {
  var files = document.getElementById('file_upload').files;
  if(files.length==0){
    alert("Please choose any file...");
    return;
  }
  var filename = files[0].name;
  var extension = filename.substring(filename.lastIndexOf(".")).toUpperCase();
  if (extension == '.XLS' || extension == '.XLSX') {
      //Here calling another method to read excel file into json
      excelFileToJSON(files[0]);
  }else{
      alert("Please select a valid excel file.");
  }
}

function excelFileToJSON(file){
  try {
    var reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = function(e) {

        var data = e.target.result;
        var workbook = XLSX.read(data, {
            type : 'binary'
        });
        var result = {};
        var firstSheetName = workbook.SheetNames[0];
        //reading only first sheet data
        var jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName]);
        //alert(JSON.stringify(jsonData));
        //displaying the json result into HTML table
        displayJsonToHtmlTable(jsonData);
        }
    }catch(e){
        console.error(e);
    }
}

function displayJsonToHtmlTable(jsonData){
  const pageTitle = $(document). prop('title');
  if (pageTitle === 'Создать заказ: Gration 2'){
    if ( jsonData.length>0 ){
      for(var i=1;i<jsonData.length;i++){
        var row=jsonData[i];
        var row_ar = Object.entries(row)
        /*
        Заявка для ЭТАЛОНА
        0: Array [ "Реєстраційний номер", "24/000013323" ]
        1: Array [ "Дата звернення /  дата страхового випадку", "07.10.2024 12:51\n07.10.2024 08:00" ]
        2: Array [ "Тип учасника", "Потерпілий" ]
        3: Array [ "№ полиса учасника", "АР-3906677" ]
        4: Array [ "Інформація про учасника: \nномер договору,\nтермін валідності,\nПІБ, \nномер телефону,  \nмарка та номер ТЗ", "АР-3906677\nз 22.10.2023 по 21.10.2024\nПоходенко Олександр Олександрович \n0501950344\nЖигайло Вячеслав Іванович \nTOYOTA 03221МТ" ]
        5: Array [ "Зміст випадку \n(в т.ч. указати інформацію по потерпілому)", "страхувальник на перехресті догнав атво \nПошкодження:\nбампер \nОформлено через Європротокол\nє-кол " ]
        6: Array [ "Місце ДТП\n(область, населений пункт, вулиця)", "Черкаська область, Черкаси, вул Хіміків і Сурікова " ]
        7: Array [ "Місце знаходження ТЗ", "Черкаси" ]
        8: Array [ "Необхідні послуги", "Огляд майна потерпілого" ]
        */
        console.log(row_ar);
        if (row_ar[0][0] == 'Реєстраційний номер'){
          var client_name = row_ar[4][1].split('\n')[2]
          var client_phone = row_ar[4][1].split('\n')[3]
          var ins_number = row_ar[3][1]
          var marka = row_ar[4][1].split('\n')[4].split(' ')[0]
          var model = row_ar[4][1].split('\n')[4].split(' ')[0]
          var nomer = row_ar[4][1].split('\n')[4].split(' ')[1]
          var order_number = row_ar[0][1]
          var crash_date = row_ar[1][1].split(' ')[0]
          var region = row_ar[7][1]
  
          $('#order_need_inspection').click()
          $('#autosearch').val('Кирил Царьов')
          $('#order_poster_id').val(1019);
  
          $('#order_insurance_objects_clients_name').val(client_name)
          $('#order_insurance_objects_clients_telephone').val(client_phone)
          $('#order_insurance_objects_policy').val(ins_number)
          $('#order_insurance_objects_brand').val(marka)
          $('#order_insurance_objects_object_model_name').val(model)
          $('#order_insurance_objects_nomer').val(nomer)
          $('#order_insurance_number').val(order_number)
          $('#order_crash_date').val(crash_date)
  
          $('#order_notes').val(region)
        } else {
          alert('EXCEL не ЭТАЛОН!')
        }
      }
    } else {
      alert('Excel порожнiй!');
    }
  } else {
    // ЗАГРУЗКА СЧЕТА В ДЕФЕКТЫ
    $('.add-button a').click();
    var count = jsonData.length;
    if( jsonData.length>0 ){
        for(var i=0;i<jsonData.length;i++){
          var row=jsonData[i];
          //console.log(row);
          if (row["code"] === 'robot'){
            $('.defect-name').last().val(row["name"]);
            $('.defect-rem-vozd').last()[0].getElementsByTagName('select')[0].value = 'remont'
            $('.defect-work-price').last().val(row["cena"]);
          } else {
            $('.defect-name').last().val(row["name"]+' '+row["count"]+'шт '+'('+row["code"]+')');
            $('.defect-total-price').last().val(row["cena"]);
          }
          resultof();
          $('.add-button a').click();
        }
        alert('Формування калькуляції закінчено!');
    }else{
        alert('Excel порожнiй!');
    }
  }

}

function getUploadsFromOrder(order_id){
  var mainDiv = $('#uploads_collection')
  mainDiv[0].replaceChildren();
  $('#popup')[0].replaceChildren();
  $.ajax({
    url: "/orders/get_uploads/"+order_id,
    type: "GET",
    dataType: 'json',
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
    message: 'Запитую дані',
    data: {"id": order_id},
    success: function (response) {
      //console.log(response)

      for (var i = 0; i < response.length; i++) {
        if (response[i].split('||')[1].includes('pdf') || response[i].split('||')[1].includes('wordprocessingml') || 
            response[i].split('||')[1].includes('excel') || response[i].split('||')[1].includes('excel') || response[i].split('||')[1].includes('video') || response[i].split('||')[1].includes('zip')){
              var createA = document.createElement("a")
              var createBR = document.createElement("br")
              var createAText = document.createTextNode(response[i].split('||')[2]);
              createA.setAttribute('href', response[i].split('||')[0]);
              createA.appendChild(createAText);
              document.getElementById('uploads_collection').appendChild(createA);
              document.getElementById('uploads_collection').appendChild(createBR);
        } else{
        }        
      }
      for (var i = 0; i < response.length; i++) {
        if (response[i].split('||')[1].includes('pdf') || response[i].split('||')[1].includes('wordprocessingml') || 
            response[i].split('||')[1].includes('excel') || response[i].split('||')[1].includes('excel') || response[i].split('||')[1].includes('video') || response[i].split('||')[1].includes('zip')){
        } else{
          var elem = document.createElement("img");
          elem.setAttribute("class", "image_preview")
            $(elem).attr('id', 'img_modal_'+i)
            $(elem).attr('onclick', 'prev_for_modal("img_modal_'+i+'")')
            $(elem).attr('src', response[i].split('||')[0])
          document.getElementById('uploads_collection').appendChild(elem);

        }
      }      

    }
  });
}

function prev_for_modal(img_id){
  var img = document.getElementById(img_id)
  $("#popup").html( 
    $('<img>')
      .attr('src', img.src)
      .attr('style', 'display: block; margin-left: auto; margin-right: auto;width: 70%;') )
}

function sendEndDate(){
  $.ajax({
    url: "/send_for_enddate/",
    type: "GET",
    dataType: 'json',
    statusCode: {
      404: function () {
        console.log("page not found");
      },
      500: function () {
        console.log("неправильний параметр");
      }
    },
    message: 'Запитую дані',
    data: {},
    success: function (response) {
      if (response != '0'){
        $('#count_orders_send').text('Кол. просроченных дел по осмотрам '+response+' шт.')
      } 
    }
  })
}
window.remove_fields = remove_fields
window.add_fields = add_fields
window.stoppedTyping = stoppedTyping
window.readURL = readURL
window.clearImages = clearImages
window.rgbToHex = rgbToHex
window.resultof = resultof
window.adresstotge = adresstotge
window.setSrokExp = setSrokExp
window.vindecodeshow = vindecodeshow
window.getdefect = getdefect
window.krott = krott
window.hideImageview = hideImageview
window.setPrice = setPrice
window.newwindow = newwindow
window.nextelement = nextelement
window.hideRawCalc = hideRawCalc
window.get_defects_res = get_defects_res
window.remove_all_defects = remove_all_defects
window.fpart = fpart
window.getInputsByValue = getInputsByValue
window.hideimg = hideimg
window.UpdateCompanyName = UpdateCompanyName
window.autoSearchGet = autoSearchGet
window.autocomplete = autocomplete
window.updateTime = updateTime
window.reqDefects = reqDefects
window.addPhoto_for_audahistory = addPhoto_for_audahistory
window.podpisInCheck = podpisInCheck
window.addOrderHelp = addOrderHelp
window.getOrdersInfo = getOrdersInfo
window.getAgreedInfo = getAgreedInfo
window.userLinkInfo = userLinkInfo
window.updateCommentsCount = updateCommentsCount
window.blinkerHomePage = blinkerHomePage
window.reqPhotoSemilar = reqPhotoSemilar
window.get_defects = get_defects
window.add_raw_calc = add_raw_calc
window.hideRawCalcReal = hideRawCalcReal
window.convert_to_time = convert_to_time
window.addlitoul = addlitoul
window.setBulletHour = setBulletHour
window.zamenaNaEILN = zamenaNaEILN
window.lireturn = lireturn
window.paintNormal = paintNormal
window.convert_to_time_onchange = convert_to_time_onchange
window.rst = rst
window.promUAkey = promUAkey
window.buildHtmlTable = buildHtmlTable
window.addAllColumnHeaders = addAllColumnHeaders
window.setInsFromCLient = setInsFromCLient
window.magnify = magnify
window.RemoveGreenUrlFromDefects = RemoveGreenUrlFromDefects
window.RemoveAllUrlFromDefects = RemoveAllUrlFromDefects
window.rstCalc = rstCalc
window.autoSearchGetRST = autoSearchGetRST
window.ChangeAutocomplitForBuletSearch = ChangeAutocomplitForBuletSearch
window.checkBid = checkBid
window.vinInfoText = vinInfoText
window.editPage = editPage
window.checkboxEditor = checkboxEditor
window.AnswerPosterComment = AnswerPosterComment
window.showHideLogForArtem = showHideLogForArtem
window.showHideImpotantForArtem = showHideImpotantForArtem
window.hideCatalog = hideCatalog
window.catalogSelectGroupe = catalogSelectGroupe
window.SelectOperationButton = SelectOperationButton
window.setButtonCatalog = setButtonCatalog
window.addCatalogPart = addCatalogPart
window.RemoveButtonsCatalog = RemoveButtonsCatalog
window.setColorSelectPart = setColorSelectPart
window.setColorAllSelectPart = setColorAllSelectPart
window.CheckColorPartAndButton = CheckColorPartAndButton
window.CheckPasswordCatalog = CheckPasswordCatalog
window.ColorPartBasicCatalog = ColorPartBasicCatalog
window.addCatalogSign = addCatalogSign
window.getRandomArbitrary = getRandomArbitrary
window.autoSearchGetRSTforOrderForm = autoSearchGetRSTforOrderForm
window.orderFormToggle = orderFormToggle
window.addCatalogSignGuset = addCatalogSignGuset
window.hidePartCodeSite = hidePartCodeSite
window.detectMob = detectMob
window.hiderightInfo = hiderightInfo
window.convert_to_money_with_id = convert_to_money_with_id
window.PromUAButtonShow = PromUAButtonShow
window.hideActview = hideActview
window.setLeftForPrintAct = setLeftForPrintAct
window.uploadExcel = uploadExcel
window.getUploadsFromOrder = getUploadsFromOrder
window.prev_for_modal = prev_for_modal
window.sendEndDate = sendEndDate
window.ChagedRemontVoz = ChagedRemontVoz
window.InfoTextfromVin = InfoTextfromVin
window.getResult = getResult